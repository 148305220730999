import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { partial } from 'lodash';
import { RenderSettingsParams } from '../UserSettingsForm/RenderSettings';
import { userSettingsLabels } from '../UserSettingsForm/UserSettings';
import { ReactComponent as LockImage } from '../../../../images/lock.svg';
import styles from './UserSettingsAndAccessForm.module.css';
import { PermissionMap, UserSettings, UserOrganizationSettings, Persona } from 'admin/types';
import { Checkbox } from 'admin/shared-components/Checkbox/Checkbox';
import { formatPermissionLabel } from 'admin/utils/parsePermissionsLabels';
import { BlockedByPersonaText, shouldRender } from 'admin/utils/shouldRender';
import { PermissionKey } from 'js-sdk/src/types/Persona';
import { mobxInjectSelect } from 'common/utils';
import { DotsIndicator } from 'common/components';
import { useAppSelector } from 'redux-stores';
import TCClient from 'client';

type MobxProps = {
  featureFlags: Record<string, unknown>;
  findPersona: (props: {
    personaId: string;
    selectedOrganizationId: string;
  }) => Promise<Persona | undefined>;
  getURLParams: () => Record<string, string>;
  loadPermissions: (selectedOrganizationId: string) => Promise<PermissionKey[]>;
};

type SettingGroup = {
  isPersonaForm?: boolean;
  settings: RenderSettingsParams[];
  title: string;
  userOrgSettings: UserOrganizationSettings;
};

type UserSettingsAndAccessFormProps = {
  disableAll?: boolean;
  isPersonaForm?: boolean;
  userPermissions: PermissionMap | undefined;
  userSettings: UserSettings;
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings | undefined>>;
  setUserPermissions: React.Dispatch<React.SetStateAction<PermissionMap | undefined>>;
};

function dataTestId(type: 'permission' | 'setting', label: string) {
  return `${type}-${label.toLowerCase().replaceAll(' ', '-')}`;
}

function SettingsGroup({ isPersonaForm, settings, title, userOrgSettings }: SettingGroup) {
  return (
    <>
      <div className={styles.settingsGroupTitle}>{title}</div>
      <div className={styles.settingsGroupGrid}>
        {settings
          .filter(
            ({ requiredOrgSettings, requiredIndividualCondition, forbiddenOffCondition }) =>
              userOrgSettings &&
              shouldRender({
                orgSettings: userOrgSettings,
                requiredOrgSettings,
                requiredIndividualCondition,
                forbiddenOffCondition,
              })
          )
          .map((setting) => (
            <div
              className={classNames(styles.settingsGroupGridItem, { [styles.span]: setting.span })}
              key={setting.label}
            >
              <div className={styles.container} data-testid={dataTestId('setting', setting.label)}>
                <div className={styles.left}>
                  <div className={styles.title}>{setting.label}</div>
                  {setting.lockDesc && (
                    <div className={styles.lockDesc}>
                      <LockImage />
                      {setting.lockDesc}
                    </div>
                  )}
                  <div className={styles.description}>{setting.subLabel}</div>
                </div>
                <div className={styles.right}>
                  {isPersonaForm && setting.personaSettingControl
                    ? setting.personaSettingControl
                    : setting.settingControl}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

function UserSettingsAndAccessForm({
  disableAll = false,
  featureFlags,
  findPersona,
  isPersonaForm = false,
  getURLParams,
  loadPermissions,
  userPermissions = {},
  userSettings,
  setUserSettings,
  setUserPermissions,
}: UserSettingsAndAccessFormProps & MobxProps) {
  const [userOrgSettings, setUserOrgSettings] = useState<UserOrganizationSettings>();
  const [orgPermissions, setOrgPermissions] = useState<PermissionKey[]>([]);
  const [permissions, setPermissions] = useState<PermissionMap>({});
  const [persona, setPersona] = useState<Persona | undefined | null>(null);
  const organizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const urlParams = getURLParams();

  useEffect(() => {
    TCClient.adminUsers
      .fetchOrganizationSettings({ organizationId })
      .then((org_settings) => setUserOrgSettings(org_settings));
    loadPermissions(organizationId).then((permissions) => setOrgPermissions(permissions));
  }, [loadPermissions, organizationId]);

  useEffect(() => {
    if (isPersonaForm || !userSettings.personaId) {
      setPersona(undefined);
    } else {
      if (persona) return;
      findPersona({
        selectedOrganizationId: organizationId,
        personaId: userSettings.personaId,
      }).then((p) => {
        if (!p) return;
        setPersona(p);
      });
    }
  }, [findPersona, isPersonaForm, userSettings, organizationId, persona]);

  useEffect(() => {
    if (!isPersonaForm) {
      setPermissions(userPermissions);
    } else {
      setPermissions(
        orgPermissions.reduce(
          (pMap, permission) => ({
            ...pMap,
            [permission]: userPermissions?.[permission] || false,
          }),
          {}
        )
      );
    }
  }, [isPersonaForm, orgPermissions, userPermissions]);

  const isLockedByPersona = useCallback(
    (type: 'permissions' | 'settings', title: string) => {
      if (userSettings.personaMappingExclusion) return false;
      if (type === 'permissions') {
        return !!persona?.permissions?.[title];
      } else {
        return !!persona?.settings?.[title as keyof UserSettings];
      }
    },
    [persona, userSettings]
  );

  if (!userSettings || !userOrgSettings || persona === null) {
    return (
      <div className={classNames(styles.loadingHolder)}>
        <DotsIndicator color="#0e0d0d" size={12} marginTop={30} marginRight={5} />
      </div>
    );
  }

  const { settings, patientEngagementSettings, permissionDescriptions } = userSettingsLabels(
    userSettings,
    userOrgSettings,
    setUserSettings,
    featureFlags,
    urlParams,
    disableAll,
    'v2',
    partial(isLockedByPersona, 'settings')
  );

  // TODO: This is temporary to work around server bug (USR-96), needs to be removed
  //       once the issue is resolved.
  const filteredSettings = settings.filter(
    (setting) =>
      !isPersonaForm ||
      ![
        'Message Lifespan',
        'Fast Deploy',
        'Video Calling',
        'VoIP Audio Calling',
        'Delete on Read',
        'Repeat Notifications',
        'Message Forwarding',
        'Delivery Escalations',
        'PIN Lock (mobile)',
        'Group Audio Calling',
        'Click to Call',
      ].includes(setting.label)
  );

  return (
    <div className={styles.userSettingsAndAccessForm}>
      <hr className={classNames(styles.personaHr)} />
      <div className={styles.settingsGroup}>
        <SettingsGroup
          isPersonaForm={isPersonaForm}
          title="General Settings"
          settings={filteredSettings}
          userOrgSettings={(userOrgSettings || {}) as UserOrganizationSettings}
        />
        <SettingsGroup
          isPersonaForm={isPersonaForm}
          title="Patient Engagement Settings"
          settings={patientEngagementSettings}
          userOrgSettings={(userOrgSettings || {}) as UserOrganizationSettings}
        />
      </div>
      <hr className={classNames(styles.personaHr)} />
      <div className={styles.settingsGroup}>
        <div className={styles.settingsGroupTitle}>Access</div>
        <div className={styles.settingsGroupGrid}>
          {Object.keys(permissions).map((permission) => {
            const lockedByPersona = isLockedByPersona('permissions', permission);
            return (
              <div className={styles.settingsGroupGridItem} key={permission}>
                <div
                  className={styles.container}
                  data-testid={dataTestId('permission', formatPermissionLabel(permission))}
                >
                  <div className={styles.left}>
                    <div className={styles.title}>{formatPermissionLabel(permission)}</div>
                    {lockedByPersona && (
                      <div className={styles.lockDesc}>
                        <LockImage />
                        {BlockedByPersonaText}
                      </div>
                    )}
                    <div className={styles.description}>{permissionDescriptions[permission]}</div>
                  </div>
                  <div className={styles.right}>
                    <Checkbox
                      disabled={disableAll || lockedByPersona}
                      checked={permissions[permission as keyof PermissionMap]}
                      onClick={(checked: boolean) =>
                        setUserPermissions((prev) => ({ ...prev, [permission]: checked }))
                      }
                      multiple
                      isLabelClickable={!disableAll || lockedByPersona}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<UserSettingsAndAccessFormProps, MobxProps>({
  featureStore: ['featureFlags', 'getURLParams'],
  personaStore: ['findPersona', 'loadPermissions'],
})(UserSettingsAndAccessForm);
