import React from 'react';
import { Room } from 'twilio-video';
import SearchService, {
  SearchQueryOptions as __SearchQueryOptions__,
} from '../js-sdk/src/services/SearchService';
import type { ClientConfig as __ClientConfig__ } from '../js-sdk/src/configuration';
import {
  PersonaCreateFields,
  PersonaGetFields,
  PersonaGetAllFields,
  PermissionKey,
} from '../js-sdk/src/types/Persona';
import { FeatureService } from '../models/enums/FeatureService';
import { Tag } from './Collaboration';
import { Persona } from 'admin/types';

export type ClientConfiguration = __ClientConfig__;
export type SearchQueryOptions = __SearchQueryOptions__;
export type { Appointment, Workflow, WorkflowEvent } from './AutomatedMessages';
export type { Template } from './smb';
export type {
  ActionButton,
  Color,
  PagerNumber,
  SaveRoleProps,
  TeamUpdateProps,
  RoleUpdateProps,
  Tag,
} from './Collaboration';

export type Call = {
  callId: string;
  groupId: string;
  id: string;
  memberIds: string[];
  membersStatuses: { [k: string]: string };
  organizationId: string;
  payload: {
    callerId: string;
    date: string;
    recipientToken?: string;
  };
  room: Room;
  sendDataMessage: () => void;
};

type CallLogCall = {
  createdOn: string;
  duration: number;
  isGroup: boolean;
  isVideo: boolean;
  token: string;
  type: string;
};

export type CallLogEntity = {
  avatar: string;
  displayName: string;
  members?: string[];
  metadata: {
    isPatientContact?: boolean;
    patientContactId: string;
    patientDob?: string;
    patientGender?: string;
    patientId?: string;
    patientMrn?: string;
    patientName?: string;
    phoneNumber?: string;
    relationName?: string;
  };
  numMembers?: number;
  token: string;
};

export type CallLog = {
  call: CallLogCall;
  caller: {
    type: string;
    entity: CallLogEntity;
  };
  id: string;
  isContact: boolean;
  isGroup: boolean;
  isMissed: boolean;
  isOptedOut: boolean;
  isOutgoing: boolean;
  isQuickCall: boolean;
  memberCount: number;
  organizationId: string;
  patientDetails: string[];
  target: {
    type: string;
    entity: CallLogEntity;
  };
};

export type DownloadState = {
  errorCode: number | null;
  errorMessage: string | null;
  fileBlob: Blob | null;
  isDownloading: boolean;
  isLoading: boolean;
  url: string | null;
};

export type Conversation = {
  allowedSenders: Entity[];
  featureService: string;
  groupId?: string;
  higherContinuation: { continuation: unknown; itemsEstimate: number };
  id: string;
  lastMessage?: Message;
  lowerContinuation: {
    continuation: unknown;
    itemsEstimate: number;
    fullyFetchedRole?: boolean;
    fullyFetchedUser?: boolean;
  };
  organizationId?: string;
  unreadCount: number;
  unreadPriorityCount: number;
  counterParty: Group;
  counterPartyType?: string;
  isMuted?: boolean;
};

export type Group = {
  $entityType: string;
  alertMessage: Message;
  conversation?: Conversation;
  displayName?: string;
  escalationExecution?: unknown;
  groupType: GroupType;
  id: string;
  memberIds?: string[];
  members: User[];
  name?: string;
  dnd?: boolean;
  dndText?: string;
  organizationId?: string;
  p2pRecipient?: User;
  p2pRecipientType?: unknown;
  tag?: Tag;
  team?: Team;
  patientContextId?: string;
  patientDetails?: { smsOptedOut?: boolean };
  preserve?: boolean;
  proxiedMembers?:
    | {
        [key: string]: Array<{ token: string }>;
      }
    | Record<string, never>;
};

export const GroupTypes = {
  GROUP: 'GROUP',
  ROLE_P2P: 'ROLE_P2P',
  PATIENT_MESSAGING: 'PATIENT_MESSAGING',
  PATIENT_CARE: 'PATIENT_CARE',
  ACTIVATED_TEAM: 'ACTIVATED_TEAM',
  INTRA_TEAM: 'INTRA_TEAM',
  ESCALATION: 'ESCALATION',
  FORUM: 'FORUM',
} as const;

export type GroupType = keyof typeof GroupTypes;

export type AlertComponentType =
  | 'bold_text_view'
  | 'button_view'
  | 'button_view'
  | 'completed_item'
  | 'escalate_header'
  | 'nurse_header'
  | 'role_info'
  | 'text_view'
  | 'two_button_view';

export type AlertComponentAction = {
  action_type: 'call' | 'external_url';
  action_url: string;
  border_color?: string;
  headers: Record<string, string>;
  params: {
    internal_message_id: string;
    message_id: string;
    recipient: string;
    return_value: string;
    sender_id: string;
    signature: string;
  };
  read_message: boolean;
  type: AlertComponentType;
  value: string;
  value_color?: string;
  value_type: string;
};

export type AlertComponent = {
  bg_color?: string;
  data?: AlertComponentAction[];
  sender_display_name?: string;
  role_display_name?: string;
  sub_type?: number;
  timestamp?: string;
  type: AlertComponentType;
  value?: string;
  value_color?: string;
  value_type?: string;
  wrappable?: number;
} & AlertComponentAction;

export type Message = {
  $placeholder?: boolean;
  alertCard?: AlertComponent[];
  alertDetails?: {
    includesCurrentUserOrRole?: boolean;
    isPrimaryAction?: boolean;
    primaryAction?: string;
  };
  alertConversationGroup?: Group;
  alertRecipients: User[];
  attachments?: Array<AttachmentType>;
  body: string;
  conversation?: Conversation;
  counterParty: Group;
  counterPartyType: string;
  createdAt: Date;
  currentRecipientAlertAction?: MessageStatusPerRecipient;
  featureService: FeatureService;
  group: Group;
  id: string;
  isForwarded?: boolean;
  isHistoricalAlert: boolean;
  isOutgoing: boolean;
  isUnread: boolean;
  originalSender?: User;
  shouldEnsureRecipientStatus: boolean;
  statusesPerRecipient: Array<MessageStatusPerRecipient>;
  sender?: User;
  senderId?: unknown;
  serverId?: string;
};

export type AttachmentType = {
  id: string;
  contentType: string;
  name: string;
};

export type MessageAttachmentPropTypes = {
  id: string;
  size: number;
  contentType: string;
  name?: string;
  localPath?: string;
};

export const MessageStatuses = {
  NEW: 'NEW',
  DELIVERED: 'DELIVERED',
  READ: 'READ',
} as const;

export type MessageStatusPerRecipient = {
  id: string;
  status: keyof typeof MessageStatuses;
  reaction: string;
  reactionTimestamp: Date;
  user: User;
  userId: string;
};

export type NetworkType = 'provider' | 'patient';

export type Organization = {
  alertMessages: Message[];
  billing: string;
  canAdminRoles?: boolean;
  canEscalateMessages?: boolean;
  emailDomains: string;
  id: string;
  isContacts: boolean;
  memberCount: number;
  name: string;
  paid: boolean;
  pending: boolean;
  pendingAdminApproval?: boolean;
  roleTransition?: string;
  rolesEnabled?: boolean;
  showTeams?: boolean;
  token: string;
  totalMembers: number;
  unreadAlertsCount: number;
};

export type QueryReponse = { metadata: { continuation?: string }; results: { entity: Role }[] };

type RoleEscalationPathTarget = {
  display_name: string;
  token: string;
  type?: string;
};

type RoleEscalationPath<Target = RoleEscalationPathTarget> = {
  duration: number;
  targets?: Target[];
};

export type RoleEscalationPolicy<Target = RoleEscalationPathTarget> = {
  always_escalate?: boolean;
  escalation_path?: RoleEscalationPath<Target>[];
  target?: string;
  target_duration?: number;
};

export type RoleMetadata = {
  escalation_policy: RoleEscalationPolicy;
  exclude_private_group: boolean;
  no_owners_message: string;
  open_assignment: boolean;
  owner_required: boolean;
  replay_history: boolean;
  role_transition: boolean;
  room_assignment_id?: string;
} & TagData;

export type recipientEntity = User | Role | Team | Group;
export type SearchResultList = Array<recipientEntity>;

export type SearchEntity = {
  entity: {
    $entityType: string;
    displayName: string;
    groupType: string;
    id: string;
    isPatient: boolean;
    isPatientContact: boolean;
    messageAnyoneRecipient: string;
    network: string;
    patientDetails: string;
    roleTag: {
      isDefault: boolean;
    };
  };
  label: string;
  organizationId: string;
  value: string;
};

export type Role = {
  $entityType: string;
  accountType?: string;
  avatar?: string;
  bookmark?: string;
  botUserId?: string;
  botUser?: User;
  description: string;
  disabledCapabilities?: string[];
  displayName: string;
  dnd?: boolean;
  dndText?: string;
  hasCurrentUserOrRole?: boolean;
  lastLoginTime?: string;
  members?: User[];
  memberIds?: string[];
  metadata?: RoleMetadata;
  organizationId?: string;
  orgPagers?: string[];
  pagers?: string[];
  tagId?: string;
  status?: string;
  id: string;
  username?: string;
  tag?: Tag;
  isRoleBot: boolean;
  botRole: { memberIds: string[] };
};

export type SearchResult = {
  id: string;
  entity: {
    accountStatus?: string;
  };
  entityType: string;
  metadata?: Record<string, unknown>;
};

export type TagData = {
  tag_color: string;
  tag_hex?: string;
  tag_id: string;
  tag_name: string;
};

export type PBXTab = 'User Lines' | 'Roles Lines' | 'Nurse Call Users';

export type PBXLinesList = {
  customSettings: {
    extensionNumber: string;
  };
  id: string;
  targetId: string | null;
  targetType: 'Role' | 'User' | 'NurseCall';
};

export type PBXResponse = {
  content: PBXLinesList[];
  metaData: {
    totalPages: number;
    totalElements: number;
    currentSize: number;
    currentPage: number;
  };
};

export interface EventMessage {
  counterParty: { displayName: string };
}
export interface ScimConfiguration {
  baseUrl: string;
  status: string;
  hasAdmin: boolean;
  prefix: string;
  suffix: string;
}

export type Attribute = {
  name: string;
  description: string;
  subAttributes: Attribute[];
};

type AvailableAttributes = {
  attributes: Attribute[];
};

type SetupScimToken = {
  token: string;
};

export type SetupScimParam = {
  suffix?: string;
  prefix?: string;
  organizationId: string;
  clientName: string;
};

export type Stores = {
  messengerStore: {
    isPresenceFeatureAllowed: boolean;
    currentOrganizationId: string;
  };
};

export type TCClient = {
  adminOrganizations: {
    getAllPermissionRoles: ({ orgId }: { orgId: string }) => { roles: PermissionKey[] };
  };
  presence: {
    addUser: (userid: string) => void;
    removeUser: (userid: string) => void;
  };
  persona: {
    findPersona: (fields: PersonaGetFields) => Persona;
    findPersonas: (fields: PersonaGetAllFields) => Persona[];
    findTitles: (organizationId: string) => { titles: string[] };
    createPersona: (payload: PersonaCreateFields, orgId: string) => { titles: string[] };
    deletePersona: (personaId: string, orgId: string) => void;
    updatePersona: (payload: PersonaCreateFields, personaId: string, orgId: string) => Persona;
  };
  scim: {
    findSettings: (organizationId: string) => Promise<ScimConfiguration>;
    findBearerToken: (organizationId: string) => Promise<SetupScimToken>;
    setupScim: (param: SetupScimParam) => Promise<SetupScimToken>;
    findAvailableSCIMFields: (organizationId: string) => Promise<AvailableAttributes>;
  };
  roles: { findRolesSchedulerCsv: (organizationId: string) => Promise<string> };
  search: SearchService;
  config: __ClientConfig__;
  messages: {
    injectToConversation: (
      conversationId: string,
      body: string,
      options?: Record<string, unknown>
    ) => void;
    processAlertMetadata: (messageServerId: string, payload: Record<string, unknown>) => void;
  };
  on: (type: string, listener: (Message: EventMessage) => void) => void;
  removeListener: (type: string, listener: (Message: EventMessage) => void) => void;
  signInWithJwtToken: (
    jwtToken: string,
    params?: unknown
  ) => Promise<{ auth: unknown; user: unknown }>;
  webInfraUrl: string;
};

export type Team = {
  $entityType: string;
  canMembersLeave?: boolean;
  hasCurrentUserBeenDeclined?: boolean;
  hasCurrentUserOrRole?: boolean;
  hasCurrentUserPending?: boolean;
  canRequestToJoin?: boolean;
  description?: string;
  displayName?: string;
  id: string;
  memberIds?: string[];
  memberCount?: number;
  members?: User[];
  name?: string;
  organizationId?: string;
  tag?: Tag;
  tagId?: string;
  group?: Group;
};

type ProfileByOrganizationId = {
  [key: string]: {
    autoForwardReceiverIds: string[];
    dndAutoForwardEntities: string[];
    autoForwardReceivers: User[];
    conversationId: string;
    department: string;
    organizationId: string;
    title: string;
    userId: string;
  };
};

export type User = {
  $entityType: string;
  avatarUrl: string;
  botRole?: Role;
  botRoleId?: string;
  botUser?: User;
  department: string;
  displayName: string;
  dnd?: boolean;
  dndText?: string;
  firstName: string;
  id: string;
  isAlertsUser: boolean;
  isPatient: boolean;
  isPatientContact: boolean;
  isRoleBot: boolean;
  lastName: string;
  mfaEnabled?: boolean;
  presenceStatus: string;
  profileByOrganizationId?: ProfileByOrganizationId;
  roles?: Role[];
  shouldDisplay: boolean;
  tagId?: string;
  title?: string;
  token?: string;
};

export type VWRStaff = {
  name: string;
  accountToken: string;
  notificationStatus: 'OFF' | 'ON';
};

export type VirtualWaitingRoomStatus = 'OPEN' | 'CLOSED';

export type ReasonKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

export type VisitReason = {
  reasonKey: ReasonKey;
  reasonValue: string;
};

export type VWR = {
  id: string;
  joinUrl?: string;
  name: string;
  status: VirtualWaitingRoomStatus;
  staff: VWRStaff[];
  version: number;
  visitReasons?: VisitReason[];
};

export const VisitStatuses = {
  INCOMPLETE: 'Active Patients',
  NEW: 'New',
  INTAKE: 'Intake',
  READY: 'Ready',
  TREATING: 'Treating',
  FOLLOW_UP: 'Follow-up',
  COMPLETED: 'Completed',
} as const;

export type VisitStatus = keyof typeof VisitStatuses;

export type ActiveCall = {
  id: string;
  ended?: boolean;
  endedAt?: string;
  createdAt?: string;
};

export type VisitorResponse = {
  activeCall: null | ActiveCall;
  assignedStaffAccountId: string;
  conversationId: string;
  groupId: string;
  id: string;
  organizationId: string;
  reason?: string;
  reasonKey: ReasonKey;
  status: VisitStatus;
  version: number;
  visitorAccountToken: string;
  visitorFullName: string;
  visitorJoinedAt: string;
  visitorMobilePhone: string;
};

export type Visitor = VisitorResponse & {
  isSelected: boolean;
  conversation: Conversation;
  roomId: string;
};

export type VisitorEntity = Visitor & { roomId: string; organizationId: string };

export const ActionStatuses = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;

export type ActionStatus = keyof typeof ActionStatuses;

export type CollabEntity = Role | Team;

export type ReactElementDict = {
  [key: string]: React.ElementType;
};

type BaseEntity<T> = T extends string
  ? {
      avatarUrl?: string;
      department?: string;
      displayName: string;
      dnd?: boolean;
      dndText?: string;
      $entityType: T;
      firstName?: string;
      groupType?: GroupType | null;
      botUserId?: string | null;
      botUser: User;
      hasCurrentUser: boolean;
      id: string;
      isPatient: boolean;
      isPatientContact: boolean;
      isVisitor: boolean;
      lastName?: string;
      memberCount: number;
      members: { id: string; displayName: string }[];
      network: string;
      patientContextId?: string;
      profileByOrganizationId?: ProfileByOrganizationId;
      patientContextDetails?: {
        dob?: string;
        firstName?: string;
        lastName?: string;
        gender?: string;
        mrn?: string;
      };
      presenceStatus?: 'AVAILABLE' | 'UNAVAILABLE' | 'DND';
      recipientId: string;
      rol_name?: string;
      recipientName: string;
      tag?: {
        color: string;
        isDefault: boolean;
        displayName: string;
      } | null;
      title?: string;
    }
  : never;

export type PatientCareGroupEntity = BaseEntity<'group'> & {
  groupType: 'PATIENT_CARE';
  patientDetails: {
    DOB: string;
    MRN: string;
  };
};

export type PatientContactEntity = BaseEntity<'user'> & {
  isPatient: false;
  isPatientContact: true;
  patientContact: { relation: string; smsOptedOut?: boolean };
};

export type PatientEntity = BaseEntity<'user'> & {
  isPatient: true;
  isPatientContact: false;
  patient: {
    contactsIds?: string[];
    contacts?: { user: BaseEntity<'user'> }[];
    dob?: string;
    gender?: string;
    mrn?: string;
    smsOptedOut?: boolean;
  };
};

export type TeamEntity = BaseEntity<'team'> & { description?: string };

export type Entity =
  | BaseEntity<'user' | 'group' | 'distributionList' | 'role' | 'forum' | 'careTeam'>
  | PatientEntity
  | PatientContactEntity
  | PatientCareGroupEntity
  | TeamEntity;
