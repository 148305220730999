import BaseService from './BaseService';

export default class ScimService extends BaseService {
  async findSettings(organizationId: string) {
    return await this.host.api.scim.findSettings(organizationId);
  }

  async findBearerToken(organizationId: string) {
    return await this.host.api.scim.findBearerToken(organizationId);
  }

  async findScimUser(organizationId: string, userId: string) {
    return await this.host.api.scim.findScimUser(organizationId, userId);
  }

  async setupScim(params: { suffix?: string; prefix?: string; organizationId: string }) {
    return await this.host.api.scim.setupScim(params);
  }

  async findAvailableSCIMFields(organizationId: string) {
    return await this.host.api.scim.findAvailableSCIMFields(organizationId);
  }
}
