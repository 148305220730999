import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrolling from '../../models/enums/Scrolling';
import { Scrollbars } from './';

class ScrollerStickToBottom extends Component {
  static propTypes = {
    children: PropTypes.node,
    moreContentAvailableBottom: PropTypes.bool,
    moreContentAvailableTop: PropTypes.bool,
    onScroll: PropTypes.func,
    scrollOffset: PropTypes.number,
    showMoreContent: PropTypes.func,
    thumbMarginTop: PropTypes.number,
    preventAutoScroll: PropTypes.bool,
  };

  static defaultProps = {
    moreContentAvailableBottom: false,
    moreContentAvailableTop: false,
  };

  _isScrolledByCommand = true;
  _isScrolledToBottom = true;
  _offsetFromBottom = 0;
  _prevScrollHeight = 0;
  _prevScrollTop = 0;
  _style = null;
  _userDidFirstScroll = false;
  _userScrollActive = false;

  componentDidMount() {
    const scrollbarValues = this.scrollbar.getValues();
    this._onValuesUpdate(scrollbarValues);
  }

  componentDidUpdate() {
    const scrollbarValues = this.scrollbar.getValues();
    this._onValuesUpdate(scrollbarValues);
  }

  render() {
    const { children, thumbMarginTop } = this.props;

    return (
      <Scrollbars
        onScrollFrame={this._onScrollFrame}
        onScrollStart={this._onScrollStart}
        onScrollStop={this._onScrollStop}
        onUpdate={this._onValuesUpdate}
        ref={this._setScrollbars}
        thumbMarginTop={thumbMarginTop}
      >
        {children}
      </Scrollbars>
    );
  }

  _setScrollbars = (ref) => {
    this.scrollbar = ref;
  };

  _onScrollFrame = (values) => {
    const { scrollTop, scrollHeight } = values;
    this._offsetFromBottom = scrollHeight - scrollTop;
  };

  _onScrollStart = () => {
    this._userScrollActive = !this._isScrolledByCommand;
    this._userDidFirstScroll = this._userDidFirstScroll || !this._isScrolledByCommand;
  };

  _onScrollStop = () => {
    const { moreContentAvailableBottom, moreContentAvailableTop, onScroll, showMoreContent } =
      this.props;
    const scrollbarValues = this.scrollbar.getValues();
    if (!scrollbarValues) return;

    const { clientHeight, scrollHeight, scrollTop } = scrollbarValues;
    const direction =
      this._prevScrollTop < scrollTop
        ? 'DOWN'
        : this._prevScrollTop > scrollTop
        ? 'UP'
        : 'NO_CHANGE';
    const isScrolledToBottom =
      Math.abs(clientHeight + scrollTop - scrollHeight) < Scrolling.NEAR_BOTTOM;

    if (onScroll) {
      onScroll({
        direction,
        isScrolledToBottom,
        isUserScroll: this._userScrollActive,
      });
    }

    this._isScrolledByCommand = false;
    this._isScrolledToBottom = isScrolledToBottom;
    this._prevScrollTop = scrollTop;
    this._userScrollActive = false;

    if (scrollTop < Scrolling.NEAR_TOP && moreContentAvailableTop) {
      showMoreContent && showMoreContent('TOP');
    }

    if (isScrolledToBottom && moreContentAvailableBottom) {
      showMoreContent && showMoreContent('BOTTOM');
    }
  };

  _onValuesUpdate = (scrollbarValues) => {
    if (!scrollbarValues) return;

    const { scrollHeight, scrollTop } = scrollbarValues;

    const grew = scrollHeight > this._prevScrollHeight;
    this._prevScrollHeight = scrollHeight;

    if (!this._userScrollActive) {
      this._prevScrollTop = scrollTop;
    }

    if (grew && !this.props.preventAutoScroll) {
      if (this._isScrolledToBottom && !this._userScrollActive && this._userDidFirstScroll) {
        this.scrollToBottom(scrollbarValues);
      } else if (!this._isScrolledByCommand) {
        this.scrollToPreviousHeight(scrollbarValues);
      }
    }
  };

  isScrolledToBottom() {
    return this._isScrolledToBottom;
  }

  scrollToBottom(scrollbarValues) {
    const { moreContentAvailableBottom } = this.props;
    if (!scrollbarValues) scrollbarValues = this.scrollbar.getValues();
    if (!scrollbarValues) return;

    const { clientHeight, scrollHeight, scrollTop } = scrollbarValues;
    const newScrollTop = scrollHeight - clientHeight;
    this._isScrolledToBottom = true;

    if (scrollTop !== newScrollTop && !moreContentAvailableBottom) {
      this._isScrolledByCommand = true;
      this.scrollbar.scrollTop(newScrollTop);
    }
  }

  scrollToPreviousHeight(scrollbarValues) {
    if (!scrollbarValues) scrollbarValues = this.scrollbar.getValues();
    if (!scrollbarValues) return;

    const { scrollHeight, scrollTop } = scrollbarValues;
    const newScrollTop = scrollHeight - this._offsetFromBottom;

    if (scrollTop !== newScrollTop) {
      this._isScrolledByCommand = true;
      this.scrollbar.scrollTop(newScrollTop);
    }
  }

  scrollToElement(element) {
    const { scrollOffset } = this.props;

    const scrollbarValues = this.scrollbar.getValues();
    if (!scrollbarValues || !element) return;

    const elementTop = element.getBoundingClientRect().top;
    const scrollTop = elementTop + scrollbarValues.scrollTop - scrollOffset;

    this.scrollbar.scrollTop(scrollTop);
  }
}

export default ScrollerStickToBottom;
