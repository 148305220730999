import styles from '../index.module.css';
import TCClient from '../../../../../client';
import { RenderSettingsParams } from './RenderSettings';
import { OrganizationSettings, UserSettings, UserOrganizationSettings } from 'admin/types';
import { BlockedByPersonaText } from 'admin/utils/shouldRender';

import { TTL_CONTROL_OPTIONS, PINLOCK_TTL_CONTROL_OPTIONS } from 'admin/utils/commonControlOptions';

import { Slider } from 'admin/shared-components/Slider/Slider';
import { Dropdown } from 'admin/shared-components/Dropdown/Dropdown';
import { Checkbox, CheckboxProps } from 'admin/shared-components/Checkbox/Checkbox';
import {
  isIndividual,
  hasOrgFeatureOrSettings as hasFeature,
} from 'admin/utils/renderSettingConditions';

type RenderCheckBoxSettings = {
  userSettingKey: keyof UserSettings;
} & CheckboxProps;

export const userSettingsLabels = (
  userSettings: UserSettings,
  orgSettings: UserOrganizationSettings,
  setUserSettings: (us: UserSettings) => void,
  featureFlags: Record<string, unknown>,
  urlParams: Record<string, string>,
  forceDisableAll = false,
  version: 'v1' | 'v2' = 'v1',
  isLocked?: (settingName: string) => boolean
) => {
  const getIsIndividual = (setting: keyof UserOrganizationSettings) =>
    isIndividual(orgSettings, setting);
  const hasOrgFeatureOrSettings = (settings: Array<keyof UserOrganizationSettings>) =>
    hasFeature(orgSettings, settings);
  const initialTTLIndex = TTL_CONTROL_OPTIONS.findIndex(
    ({ value }) => value === userSettings?.messageLifespan
  );
  const isV2 = version === 'v2';

  const currentInitialTTLIndex =
    initialTTLIndex === -1 ? TTL_CONTROL_OPTIONS.length - 1 : initialTTLIndex;

  const renderCheckbox = (props: RenderCheckBoxSettings): JSX.Element => {
    const { userSettingKey, ...rest } = props;
    if (forceDisableAll) rest.disabled = true;
    if (isLocked && isLocked(userSettingKey)) rest.disabled = true;
    return (
      <Checkbox
        checked={!!userSettings[userSettingKey]}
        onClick={(v) => setUserSettings({ ...userSettings, [userSettingKey]: v })}
        {...rest}
      />
    );
  };

  const settings: RenderSettingsParams[] = [
    {
      label: 'Message Lifespan',
      lockDesc: isLocked?.('messageLifespan') ? BlockedByPersonaText : undefined,
      subLabel: 'Sent messages will be deleted after this time limit',
      span: true,
      settingControl: (
        <Slider
          onChange={(s) => setUserSettings({ ...userSettings, messageLifespan: s })}
          initialIndex={currentInitialTTLIndex}
          initialLabel={TTL_CONTROL_OPTIONS[currentInitialTTLIndex]?.label}
          options={TTL_CONTROL_OPTIONS}
          disabled={forceDisableAll}
        />
      ),
    },
    {
      label: 'Delete on Read',
      lockDesc: isLocked?.('isDeleteOnReadEnabled') ? BlockedByPersonaText : undefined,
      subLabel: 'All messages will be deleted one minute after opening',
      settingControl: renderCheckbox({
        userSettingKey: 'isDeleteOnReadEnabled',
      }),
    },
    {
      label: 'PIN Lock (mobile)',
      lockDesc: isLocked?.('pinLock') ? BlockedByPersonaText : undefined,
      span: true,
      subLabel:
        'Users are required to enter their PIN code on the mobile application after this time limit.',
      settingControl: (
        <Dropdown
          customCssClass={isV2 ? 'wide' : undefined}
          defaultValue={userSettings.pinLock}
          options={PINLOCK_TTL_CONTROL_OPTIONS}
          disabled={forceDisableAll}
          onChange={(_, i) => {
            setUserSettings({
              ...userSettings,
              pinLock: parseInt(PINLOCK_TTL_CONTROL_OPTIONS[i].value, 10),
            });
          }}
        />
      ),
    },
    {
      label: 'Repeat Notifications',
      lockDesc: isLocked?.('areRepeatNotificationsEnabled') ? BlockedByPersonaText : undefined,
      subLabel:
        'Users will receive a new alert sound every 2 minutes, up to 20 minutes, until the message is viewed.',
      settingControl: renderCheckbox({
        userSettingKey: 'areRepeatNotificationsEnabled',
      }),
    },
    {
      label: 'Fast Deploy',
      lockDesc: isLocked?.('isFastDeployEnabled') ? BlockedByPersonaText : undefined,
      subLabel: 'Users not logged in will receive TigerConnect messages via email and SMS.',
      settingControl: renderCheckbox({
        userSettingKey: 'isFastDeployEnabled',
      }),
    },
    {
      label: 'Message Forwarding',
      lockDesc: isLocked?.('isMessageForwardingEnabled') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable users to forward TigerConnect messages to others within the organization.',
      settingControl: renderCheckbox({
        userSettingKey: 'isMessageForwardingEnabled',
      }),
    },
    {
      requiredOrgSettings: ['featureClickToCall', 'clickToCallSetting'],
      label: 'Click to Call',
      lockDesc: isLocked?.('isClickToCallEnabled')
        ? BlockedByPersonaText
        : orgSettings.clickToCallSetting.clickToCallStatusSetting !== 'individual' && isV2
        ? 'Controlled by Admin'
        : undefined,
      subLabel:
        'Enable this feature to allow your users to one-tap call each other within TigerConnect. Only works for users with verified mobile numbers.',
      settingControl: (
        <div>
          {renderCheckbox({
            userSettingKey: 'isClickToCallEnabled',
            disabled: orgSettings.clickToCallSetting.clickToCallStatusSetting !== 'individual',
          })}
          {orgSettings.clickToCallSetting.clickToCallStatusSetting !== 'individual' && !isV2 && (
            <span className={styles.clickToCallAdmin}>Controlled by Admin</span>
          )}
        </div>
      ),
    },
    {
      requiredOrgSettings: ['isVoipCallingEnabled'],
      label: 'VoIP Audio Calling',
      lockDesc: isLocked?.('isVoipCallingEnabled') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to make and receive VoIP audio calls.',
      settingControl: renderCheckbox({
        userSettingKey: 'isVoipCallingEnabled',
      }),
    },
    {
      forbiddenOffCondition: 'groupAudioCallingSetting',
      label: 'Group Audio Calling',
      lockDesc: isLocked?.('groupAudioCallingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to make and receive group audio calls.',
      settingControl: renderCheckbox({
        userSettingKey: 'groupAudioCallingSetting',
        disabled: !(
          hasOrgFeatureOrSettings(['isVoipCallingEnabled']) &&
          getIsIndividual('groupAudioCallingSetting')
        ),
      }),
    },
    {
      requiredOrgSettings: ['isVideoCallEnabled'],
      label: 'Video Calling',
      lockDesc: isLocked?.('isVideoCallingEnabled') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to make and receive video calls.',
      settingControl: renderCheckbox({ userSettingKey: 'isVideoCallingEnabled' }),
    },
    {
      forbiddenOffCondition: 'groupVideoCallingSetting',
      label: 'Group Video Calling',
      lockDesc: isLocked?.('groupVideoCallingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to make and receive group video calls.',
      settingControl: renderCheckbox({
        userSettingKey: 'groupVideoCallingSetting',
        disabled: !(
          userSettings.isVideoCallingEnabled && getIsIndividual('groupVideoCallingSetting')
        ),
      }),
      personaSettingControl: renderCheckbox({
        userSettingKey: 'groupVideoCallingSetting',
        disabled: !(orgSettings.isVideoCallEnabled && getIsIndividual('groupVideoCallingSetting')),
      }),
    },
    {
      requiredOrgSettings: ['featureDeliveryEscalation'],
      label: 'Delivery Escalations',
      lockDesc: isLocked?.('areDeliveryEscalationsEnabled') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable SMS delivery if TigerConnect messages are undeliverable after 5 minutes.',
      settingControl: renderCheckbox({ userSettingKey: 'areDeliveryEscalationsEnabled' }),
    },
    {
      requiredOrgSettings: ['featurePriorityMessaging'],
      requiredIndividualCondition: 'priorityMessagingSetting',
      label: 'Priority Messaging',
      lockDesc: isLocked?.('priorityMessagingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable users to send high priority messages.',
      settingControl: renderCheckbox({ userSettingKey: 'priorityMessagingSetting' }),
    },
    {
      requiredOrgSettings: ['featureEnterprisePbx'],
      forbiddenOffCondition: 'pbxIntegrationSetting',
      show: !!(urlParams.allowPbxIntegration || featureFlags?.pbx),
      label: 'Enterprise PBX Integration',
      lockDesc: isLocked?.('pbxIntegrationSetting') ? BlockedByPersonaText : undefined,
      subLabel:
        'Enable users to access PBX calling capabilities within TigerConnect. (Requires PBX enterprise network integration)',
      settingControl: renderCheckbox({
        userSettingKey: 'enterprisePbxSetting',
        disabled: !getIsIndividual('pbxIntegrationSetting'),
      }),
    },
    {
      requiredOrgSettings: ['featureTeams'],
      requiredIndividualCondition: 'teamsSetting',
      label: 'Teams',
      lockDesc: isLocked?.('teamsSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to create and activate teams',
      settingControl: renderCheckbox({ userSettingKey: 'teamsSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientContext'],
      requiredIndividualCondition: 'patientContextSetting',
      label: 'Patient Reference',
      lockDesc: isLocked?.('patientContextSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to start a group message about a specific patient.',
      settingControl: renderCheckbox({ userSettingKey: 'patientContextSetting' }),
    },
    {
      requiredOrgSettings: ['featureTigerSchedule'],
      requiredIndividualCondition: 'tigerScheduleSetting',
      label: 'TigerConnect Physician Scheduling',
      lockDesc: isLocked?.('tigerScheduleSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to access TigerConnect Physician Scheduling applications.',
      settingControl: renderCheckbox({ userSettingKey: 'tigerScheduleSetting' }),
    },
    {
      requiredIndividualCondition: 'multiFactorAuthSetting',
      show: !!(featureFlags?.MFA || urlParams.allowMfa),
      label: 'MFA Authentication',
      lockDesc: isLocked?.('multiFactorAuthSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Will enable the MFA Authentication process during login for this user.',
      settingControl: renderCheckbox({
        userSettingKey: 'multiFactorAuthSetting',
        disabled: !getIsIndividual('multiFactorAuthSetting'),
      }),
    },
  ];

  const patientEngagementSettings: RenderSettingsParams[] = [
    {
      requiredOrgSettings: ['featurePatientEngagement'],
      requiredIndividualCondition: 'patientEngagementSetting',
      label: 'TigerConnect Patient Engagement',
      lockDesc: isLocked?.('patientEngagementSetting') ? BlockedByPersonaText : undefined,
      subLabel:
        'Enable this feature to allow your users to communicate with patients. You can choose to enable this feature for your entire organization or enable this feature to individual users.',
      settingControl: renderCheckbox({ userSettingKey: 'patientEngagementSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientQuickAdd'],
      requiredIndividualCondition: 'patientQuickAddSetting',
      label: 'Patient Quick Add',
      lockDesc: isLocked?.('patientQuickAddSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow users to quickly add a patient from their mobile application.',
      settingControl: renderCheckbox({
        userSettingKey: 'patientQuickAddSetting',
        disabled: !(
          userSettings.patientEngagementSetting || orgSettings.patientEngagementSetting === 'all'
        ),
      }),
    },
    {
      requiredOrgSettings: ['featurePatientQuickCall'],
      requiredIndividualCondition: 'patientQuickCallSetting',
      label: 'Patient Quick Call',
      lockDesc: isLocked?.('patientQuickCallSetting') ? BlockedByPersonaText : undefined,
      subLabel:
        'Allow users to quickly dial any telephone number using masked cellular calling from their mobile application.',
      settingControl: renderCheckbox({
        userSettingKey: 'patientQuickCallSetting',
        disabled: !(
          userSettings.patientEngagementSetting || orgSettings.patientEngagementSetting === 'all'
        ),
      }),
    },
    {
      requiredOrgSettings: ['featurePatientQuickCallVideo'],
      requiredIndividualCondition: 'patientQuickCallVideoSetting',
      label: 'Patient Quick Call Video',
      lockDesc: isLocked?.('patientQuickCallVideoSetting') ? BlockedByPersonaText : undefined,
      subLabel:
        'Allow users to quickly make video calls to patients from their mobile application.',
      settingControl: renderCheckbox({ userSettingKey: 'patientQuickCallVideoSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientVoip'],
      requiredIndividualCondition: 'patientVoiceCallingSetting',
      show: !!urlParams.pfVoiceCall,
      label: 'Voice Calling',
      lockDesc: isLocked?.('patientVoiceCallingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable users to voice call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientVoiceCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featureGroupAudioCalling'],
      forbiddenOffCondition: 'groupAudioCallingSetting',
      show: !!urlParams.pfGroupVoiceCall,
      label: 'Group Voice Calling',
      lockDesc: isLocked?.('patientGroupVoiceCallingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable users to group voice call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientGroupVoiceCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featureVideoCall'],
      requiredIndividualCondition: 'patientVideoCallingSetting',
      label: 'Video Calling',
      lockDesc: isLocked?.('patientVideoCallingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable users to video call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientVideoCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientGroupVideoCalling'],
      requiredIndividualCondition: 'patientGroupVideoCallingSetting',
      label: 'Group Video Calling',
      lockDesc: isLocked?.('patientGroupVideoCallingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Enable users to group video call with patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientGroupVideoCallingSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientEngagement'],
      requiredIndividualCondition: 'patientListAccessSetting',
      label: 'Patient List Access',
      lockDesc: isLocked?.('patientListAccessSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow providers to access and update patient information in the Patient List.',
      settingControl: renderCheckbox({ userSettingKey: 'patientListAccessSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientScheduledMessages'],
      requiredIndividualCondition: 'patientScheduledMessagesSetting',
      label: 'Schedule Messages',
      lockDesc: isLocked?.('patientScheduledMessagesSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow providers to schedule messages to patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientScheduledMessagesSetting' }),
    },
    {
      requiredOrgSettings: ['featurePatientVirtualWaitingRoom'],
      forbiddenOffCondition: 'patientVirtualWaitingRoomSetting',
      label: 'Virtual Waiting Room',
      lockDesc: isLocked?.('virtualWaitingRoomSetting') ? BlockedByPersonaText : undefined,
      subLabel:
        'Will enable the ability for this user to be given access to a virtual waiting room.',
      settingControl: renderCheckbox({
        userSettingKey: 'virtualWaitingRoomSetting',
        disabled: !getIsIndividual('patientVirtualWaitingRoomSetting'),
      }),
    },
    {
      requiredOrgSettings: ['featurePatientBroadcasting'],
      requiredIndividualCondition: 'patientBroadcastingSetting',
      label: 'Patient Broadcasting',
      lockDesc: isLocked?.('patientBroadcastingSetting') ? BlockedByPersonaText : undefined,
      subLabel: 'Allow providers to broadcast messages to patients.',
      settingControl: renderCheckbox({ userSettingKey: 'patientBroadcastingSetting' }),
    },
  ];

  const permissionDescriptions: Record<string, string> = {
    admin:
      'Full Administrator role, can add/view/remove users on TigerConnect, update user and org-wide settings, and assign User Permissions',
    customer:
      'Can only respond to messages that have an active conversation in their roster. They can not find broadcasts, roles, teams, or users.',
    feature_role_admin:
      "Can only update any Role's settings as well as create, manage, and delete any existing Roles",
    help_desk: "Help Desk role, can only view and update a User's settings only",
    patient_context_reporting: 'Can view Patient Context report',
    patient_data_audit_admin: 'Can only view the Patient Data report',
    patient_data_report_analytics: 'Allow the user to read the Patient Data report',
    patient_messaging_admin:
      'Can edit Patient Network Settings, add/edit/remove Patients and Contacts only',
    persona_management_admin:
      'Creates Permission Groups and view Persona Management & Persona Management Audit reports',
    provider_broadcast_list_admin:
      'Can only create/delete Provider Network Broadcasts as well as grant access to up to 30 users to search for and message a Broadcast',
    reporting: 'Access to Analytics section, including Trends and Transactions only',
    scim_integration_admin:
      'Allows the user to set up the integration of the SCIM connector as user provisioning',
    team_admin: 'Can create, manage, and delete any Teams within network only',
    tigertouchplus_reporting: 'Can only view the Patient Video report',
    virtual_waiting_room_admin:
      'Provides access to the Virtual Waiting Room section under Patient Settings',
  };

  return { settings, patientEngagementSettings, permissionDescriptions };
};

export const getUserPermissions = async (
  orgId: string,
  userId: string,
  orgSettings?: OrganizationSettings | undefined
) => {
  let settings = orgSettings;
  const _permissions = await TCClient.adminUsers.getUserPermissions({
    orgId,
    userId,
  });
  if (!settings) {
    settings = await TCClient.adminOrganizations.fetchSettings({
      organizationId: orgId,
    });
  }
  const { featureCustomFastDeploy } = settings;
  if (!featureCustomFastDeploy) delete _permissions['customer'];
  return _permissions;
};
