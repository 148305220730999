import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { last, flatten } from 'lodash';
import classNames from 'classnames';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import ResizeObserver from 'resize-observer-polyfill';
import { handlePaste } from '../../utils/handlePaste';
import MentionMemberFloatingModal, {
  HandleKeyDown,
  MODAL_LEFT_SPACE,
  MODAL_WIDTH,
} from '../Mentions/MentionMemberFloatingModal';
import FormattingButtonsContentEditable from './FormattingButtonsContentEditable';
import BEM from 'common/bem';
import shouldRunCommandBot from 'common/utils/shouldRunCommandBot';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { Entity, Conversation, Role, User } from 'types';
import { findLastMentionString } from 'common/utils/findLastMentionString';
import { getNodeAtPosition } from 'common/utils/getNodeAtPosition';

const { setMessageBodyInputFocus, setIsMentionsModalOpen } = actions;

const classes = BEM.with('MessageBodyInput');

type MentionsPayloadProps = {
  entity_type: string;
  entity_id: string;
  start: number;
  end: number;
};

type MessageBodyContentEditableInputProps = {
  addAttachments: (files: File[]) => void;
  body: string;
  borderColor?: string;
  className: string;
  currentConversation?: Conversation;
  currentUserId?: string;
  isCommandBotEnabled: boolean;
  isCommandEditorOpen: boolean;
  isComposing: boolean;
  isGiphyBotEnabled: boolean;
  onBodyChange?: () => void;
  onClick: () => void;
  onHeightChange: () => void;
  onShiftTab: (event: KeyboardEvent) => void;
  onSubmit: (event: KeyboardEvent) => void;
  onTyping?: () => void;
  richTextFormat: boolean;
  selectedRecipients: Array<Entity>;
  setBody: (body: string) => void;
  setCommandInfo: () => void;
  tabIndex: number;
  toggleCommandEditor: () => void;
  setIsComposeFromLink: (isComposeFromLink: boolean) => void;
  allowTypedMentions?: boolean;
  isComposeFromLink?: boolean;
  isNoSearchResultsOnEmptyQueryEnabled: boolean;
  isProviderNetwork?: boolean;
  logPendoTypedMention: () => void;
};

type InsertAtCursorHandle = {
  insertAtCursor: (text: string) => void;
  insertMentionAtCursor: (entities: Entity[]) => void;
};

const MessageBodyContentEditableInput = (
  {
    addAttachments,
    allowTypedMentions,
    body,
    borderColor,
    className,
    currentConversation,
    currentUserId,
    isCommandBotEnabled,
    isCommandEditorOpen,
    isComposing,
    isComposeFromLink,
    isGiphyBotEnabled,
    isNoSearchResultsOnEmptyQueryEnabled,
    onBodyChange,
    onClick,
    onHeightChange,
    onShiftTab,
    onSubmit,
    onTyping,
    selectedRecipients,
    setBody,
    setCommandInfo,
    richTextFormat,
    setIsComposeFromLink,
    tabIndex,
    toggleCommandEditor,
    isProviderNetwork,
    logPendoTypedMention,
  }: MessageBodyContentEditableInputProps,
  ref: ForwardedRef<InsertAtCursorHandle>
) => {
  const dispatch = useAppDispatch();
  const messageBodyContentEditableInputRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const mentionMemberFloatingModalRef = useRef<HandleKeyDown>(null);
  const isAtPressedRef = useRef<boolean>(false);
  const [rangeMemory, setRangeMemory] = useState<Range>();
  const [mentionMemberFloatingModal, setMentionMemberFloatingModal] = useState<boolean>(false);
  const [mentionFilter, setMentionFilter] = useState<string | null>(null);
  const [cursorPosition, setCursorPosition] = useState<{ node?: ChildNode | DocumentFragment }>();
  const [prevCaretIndex, setPrevCaretIndex] = useState<number>(0);
  const [firstEnter, setFirstEnter] = useState<boolean>(true);
  const [leftModalPosition, setLeftModalPosition] = useState<number>(0);
  const { accessibilityMode, messageBodyInputFocus } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
    messageBodyInputFocus: ui.messageBodyInputFocus,
  }));

  const flexDirection = allowTypedMentions ? 'column' : 'row';

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { altKey, key, shiftKey } = event;

      if (key === 'Enter') {
        if (mentionMemberFloatingModalRef.current?.isOpen) {
          return;
        }
        if (!(shiftKey || altKey)) {
          // Return
          onSubmit(event);
        }
      } else if (key === 'Shift' && shiftKey) {
        // TAB
        onShiftTab && onShiftTab(event);
      }
    },
    [onShiftTab, onSubmit]
  );

  useEffect(() => {
    if (accessibilityMode) return;
    if (messageBodyInputFocus && document.activeElement !== inputRef.current) {
      inputRef.current?.focus();
    } else if (!messageBodyInputFocus && document.activeElement === inputRef.current) {
      inputRef.current?.blur();
    }
  }, [accessibilityMode, messageBodyInputFocus, dispatch]);

  const deleteEmptySpanForMentionPosition = () => {
    const previousSpan = document.getElementById('emptySpanForMentionPosition');
    if (previousSpan) previousSpan.remove();
  };

  const setMentionsValues = useCallback(
    (caretIndex: number, isMentionModalActive: boolean, filter: string) => {
      setPrevCaretIndex(caretIndex);
      setMentionMemberFloatingModal(isMentionModalActive);
      setMentionFilter(filter);
      dispatch(setIsMentionsModalOpen(isMentionModalActive));
    },
    [dispatch]
  );

  const isMention = (node: Node | undefined) => node?.nodeType === 1;

  const calculateLeftPosition = () => {
    const span = document.getElementById('emptySpanForMentionPosition');
    const xCoordinate = span?.getBoundingClientRect().x || 0;
    const leftPosition =
      window.innerWidth - xCoordinate < MODAL_WIDTH
        ? window.innerWidth - MODAL_LEFT_SPACE - MODAL_WIDTH
        : xCoordinate - MODAL_LEFT_SPACE;
    setLeftModalPosition(leftPosition);
  };

  const insertNewNodeAndSetCaret = (
    firstNode: Node,
    secondNode: Node,
    thirdNode: Node,
    lastNode: Node,
    index: number,
    currentMessageInputRef: HTMLDivElement
  ) => {
    if (!currentMessageInputRef) return;
    const newNode = document.createDocumentFragment();
    newNode.appendChild(firstNode);
    newNode.appendChild(secondNode);
    newNode.appendChild(thirdNode);
    newNode.appendChild(lastNode);
    if (currentMessageInputRef.childNodes[index]) {
      currentMessageInputRef.replaceChild(newNode, currentMessageInputRef.childNodes[index]);
    }

    const sel = window.getSelection();
    if (sel && sel.rangeCount) {
      const range = sel.getRangeAt(0);
      range.deleteContents();
      range.setStartAfter(currentMessageInputRef.childNodes[index + 2]);
      range.setEndAfter(currentMessageInputRef.childNodes[index + 2]);
      sel.removeAllRanges();
      sel.addRange(range);
    }

    currentMessageInputRef.normalize();
  };

  const insertEmptySpan = useCallback(() => {
    deleteEmptySpanForMentionPosition();

    const inputText = inputRef.current?.innerText || '';
    const currentMessageInputRef = inputRef.current;
    if (currentMessageInputRef) {
      const caretIndex =
        getCaretIndex(currentMessageInputRef) === 0
          ? prevCaretIndex
          : getCaretIndex(currentMessageInputRef);
      const searchText = inputText?.slice(0, caretIndex);
      const { lastIndexPosition } = findLastMentionString(searchText);

      const {
        node: currentNode,
        startingIndex,
        index,
      } = getNodeAtPosition(currentMessageInputRef.childNodes, caretIndex - 1);

      if (currentNode && lastIndexPosition > 0 && !isMention(currentNode)) {
        const currentNodeValue = currentNode.textContent || '';

        const firstNode = document.createTextNode(
          currentNodeValue.slice(0, caretIndex - startingIndex - lastIndexPosition + 1)
        );
        const span = document.createElement('span');
        span.setAttribute('id', 'emptySpanForMentionPosition');
        const caretNode = document.createTextNode(
          currentNodeValue.slice(
            caretIndex - startingIndex - lastIndexPosition + 1,
            caretIndex - startingIndex
          )
        );
        const lastNode = document.createTextNode(
          currentNodeValue.slice(caretIndex - startingIndex)
        );
        insertNewNodeAndSetCaret(
          firstNode,
          span,
          caretNode,
          lastNode,
          index,
          currentMessageInputRef
        );
      }
      setBody(currentMessageInputRef?.innerHTML);
    }
    calculateLeftPosition();
  }, [prevCaretIndex, setBody]);

  const lookUpMentions = useCallback(
    (caretIndex: number, innerText: string) => {
      if (innerText) {
        const { filter, lastIndexPosition, atPosition } = findLastMentionString(
          innerText.slice(0, caretIndex)
        );

        let currentNode, prevNode;
        const currentMessageInputRef = inputRef.current;
        if (currentMessageInputRef) {
          currentMessageInputRef.normalize();
          currentNode = getNodeAtPosition(currentMessageInputRef.childNodes, atPosition).node;
          prevNode = getNodeAtPosition(currentMessageInputRef.childNodes, atPosition - 1).node;
        }

        if (
          lastIndexPosition > 30 ||
          lastIndexPosition === -1 ||
          isMention(currentNode) ||
          /\s/g.test(innerText.charAt(atPosition + 1))
        ) {
          setMentionsValues(caretIndex, false, '');
          return;
        }
        const shouldTriggerMention =
          isMention(prevNode) || /\s/g.test(innerText.charAt(atPosition - 1)) || atPosition === 0;
        const shouldShowMentions = filter === '' ? !isNoSearchResultsOnEmptyQueryEnabled : true;
        if (shouldTriggerMention) {
          setMentionsValues(caretIndex, shouldShowMentions, filter);
        } else {
          setMentionsValues(caretIndex, false, '');
        }
      } else {
        setMentionsValues(caretIndex, false, '');
      }
    },
    [isNoSearchResultsOnEmptyQueryEnabled, setMentionsValues]
  );

  function getCaretIndex(element: Node | null) {
    let position = 0;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount !== 0) {
        const range = window.getSelection() && window.getSelection()?.getRangeAt(0);
        if (range && element) {
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          position = preCaretRange.toString().length;
        }
      }
    }
    return position;
  }

  const handleKeyDownEvent = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      isAtPressedRef.current = key === '@';

      if (key === 'Escape' && mentionMemberFloatingModalRef.current?.isOpen) {
        setMentionMemberFloatingModal(false);
        return;
      }

      if (event.metaKey) {
        if (key === 'Backspace') {
          lookUpMentions(0, '');
        }
        return;
      }

      if (
        ['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(key) &&
        mentionMemberFloatingModalRef.current?.isOpen
      ) {
        event.preventDefault();
        flushSync(() => {
          mentionMemberFloatingModalRef.current?.handleKeyDown('');
        });
        const isShiftTab = event.shiftKey && event.key === 'Tab';
        mentionMemberFloatingModalRef.current?.handleKeyDown(isShiftTab ? 'PrevTab' : key);
        return;
      }
    },
    [lookUpMentions]
  );

  const handleKeyUpEvent = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key;
      if (
        ['Control', 'Shift', 'Alt', 'Escape', 'ArrowDown', 'ArrowUp', 'Enter'].includes(key) ||
        event.metaKey
      ) {
        return;
      }
      const caretIndex = getCaretIndex(inputRef.current);
      lookUpMentions(caretIndex, inputRef.current?.innerText || '');
    },
    [lookUpMentions]
  );

  const handleClickEvent = useCallback(() => {
    setMentionFilter(null);
    const caretIndex = getCaretIndex(inputRef.current);
    lookUpMentions(caretIndex, inputRef.current?.innerText || '');
  }, [lookUpMentions]);

  useEffect(() => {
    if (allowTypedMentions && isProviderNetwork) {
      let ref: HTMLDivElement | null = null;

      inputRef.current?.addEventListener('keydown', handleKeyDownEvent);
      inputRef.current?.addEventListener('keyup', handleKeyUpEvent);
      inputRef.current?.addEventListener('click', handleClickEvent);

      ref = inputRef.current;
      return () => {
        if (ref) {
          ref.removeEventListener('keydown', handleKeyDownEvent);
          ref.removeEventListener('keyup', handleKeyUpEvent);
          ref.removeEventListener('click', handleClickEvent);
        }
      };
    }
  }, [
    allowTypedMentions,
    handleClickEvent,
    handleKeyDownEvent,
    handleKeyUpEvent,
    isProviderNetwork,
  ]);

  useEffect(() => {
    function _focus() {
      dispatch(setMessageBodyInputFocus(true));
    }
    function _blur() {
      const selObj = window.getSelection();
      const selRange = selObj && selObj.rangeCount > 0 ? selObj.getRangeAt(0) : null;
      if (selRange) {
        setRangeMemory(selRange);
      }

      dispatch(setMessageBodyInputFocus(false));
    }
    const resizeObserver = new ResizeObserver(() => {
      onHeightChange?.();
    });
    const currentMessageInputRef = inputRef.current;
    currentMessageInputRef?.addEventListener('focus', _focus);
    currentMessageInputRef?.addEventListener('blur', _blur);
    currentMessageInputRef?.addEventListener('keypress', handleKeyPress);
    currentMessageInputRef && resizeObserver.observe(currentMessageInputRef);
    return () => {
      currentMessageInputRef?.removeEventListener('focus', _focus);
      currentMessageInputRef?.removeEventListener('blur', _blur);
      currentMessageInputRef?.removeEventListener('keypress', handleKeyPress);
      currentMessageInputRef && resizeObserver.unobserve(currentMessageInputRef);
    };
  }, [inputRef, dispatch, handleKeyPress, onHeightChange]);

  useEffect(() => {
    if (firstEnter && isComposeFromLink) {
      !accessibilityMode && dispatch(setMessageBodyInputFocus(true));
      setIsComposeFromLink(false);
    } else if (!firstEnter && !isComposeFromLink) {
      setFirstEnter(false);
    }
  }, [accessibilityMode, dispatch, firstEnter, isComposeFromLink, setIsComposeFromLink]);

  useEffect(() => {
    if (!mentionMemberFloatingModal) {
      setMentionFilter('');
    }
  }, [mentionMemberFloatingModal]);

  const insertMentionMemberSelector = () => {
    setMentionMemberFloatingModal(!mentionMemberFloatingModal);
    const div = document.createElement('div');
    div.setAttribute('class', classes('@'));
    div.textContent = '@';
    insertElementsAtCursor([div]);
  };

  const insertElementsAtCursor = (
    elms: (Element | ChildNode | DocumentFragment)[],
    cursorNode?: Element | ChildNode
  ) => {
    const selObj = window.getSelection();
    let selRange = selObj && selObj.rangeCount > 0 ? selObj.getRangeAt(0) : null;
    if (selRange?.commonAncestorContainer !== inputRef.current && rangeMemory)
      selRange = rangeMemory;
    let lastNode = last(elms);
    if (lastNode?.nodeType === 11 && lastNode.lastChild) lastNode = lastNode.lastChild;

    selRange?.deleteContents();
    elms.reverse().forEach((elm) => selRange?.insertNode(elm));

    dispatch(setMessageBodyInputFocus(true));
    setCursorPosition({ node: cursorNode || lastNode });
    setBody(inputRef?.current?.innerHTML || '');
  };

  const insertAtCursor = (text: string) => {
    const nodes = text
      .split('\n')
      .map((t) => [document.createTextNode(t), document.createElement('br')]);
    nodes[nodes.length - 1].pop();
    const fragments = new DocumentFragment();
    fragments.append(...flatten(nodes));

    insertElementsAtCursor([fragments]);
  };

  const insertMentionElementsAtCursor = (
    elms: (Element | ChildNode | DocumentFragment)[],
    cursorNode?: Element | ChildNode | null
  ) => {
    const selObj = window.getSelection();
    let selRange = selObj && selObj.rangeCount > 0 ? selObj.getRangeAt(0) : null;

    if (selRange?.commonAncestorContainer !== inputRef.current && rangeMemory)
      selRange = rangeMemory;
    let lastNode = last(elms);
    if (lastNode?.nodeType === 11 && lastNode.lastChild) lastNode = lastNode.lastChild;

    selRange?.deleteContents();
    elms.reverse().forEach((elm) => selRange?.insertNode(elm));
    dispatch(setMessageBodyInputFocus(true));
    setCursorPosition({ node: cursorNode || lastNode });
    setBody(inputRef?.current?.innerHTML || '');
  };

  const createMentionNode = (entity: Role | User) => {
    const parentSpan = document.createElement('span');
    const outSpan = document.createElement('span');
    const inSpan = document.createElement('span');
    const topSpan = document.createElement('span');
    const bottomSpan = document.createElement('span');

    outSpan.setAttribute('mentionEntityType', entity.$entityType);
    parentSpan.setAttribute('contenteditable', 'false');
    outSpan.setAttribute('mentionId', (entity as Role).botUserId || entity.id);
    inSpan.setAttribute('class', classes('mention'));
    inSpan.textContent = `@${entity.displayName}`;
    outSpan.onclick = (e: MouseEvent) => {
      const childNodes = Array.from((e.target as Node)?.parentNode?.parentNode?.childNodes || []);
      const targetIndex = childNodes.findIndex((n) => n.firstChild?.nextSibling === e.target);
      const selectedNode = childNodes[targetIndex + 1];
      setCursorPosition({ node: selectedNode });
    };
    parentSpan.append(topSpan);
    outSpan.append(inSpan);
    parentSpan.append(outSpan);
    parentSpan.append(bottomSpan);
    return parentSpan;
  };

  const insertMentionAtCursor = (entities: Entity[]) => {
    let lastInsertedNode: Node | null = null;
    const elements: (Element | ChildNode | DocumentFragment)[] = [];

    entities.forEach((entity) => {
      const parentSpan = createMentionNode(entity as unknown as Role | User);

      elements.push(parentSpan);
      const space = document.createTextNode('\xA0');
      elements.push(space);

      lastInsertedNode = space;
    });

    insertMentionElementsAtCursor(elements, lastInsertedNode);
  };

  const insertOneMentionAtCursor = (entity: User | Role) => {
    deleteEmptySpanForMentionPosition();

    const inputText = inputRef.current?.innerText || '';
    const currentMessageInputRef = inputRef.current;
    if (currentMessageInputRef) {
      currentMessageInputRef.normalize();
      const caretIndex =
        getCaretIndex(currentMessageInputRef) === 0
          ? prevCaretIndex
          : getCaretIndex(currentMessageInputRef);
      const searchText = inputText?.slice(0, caretIndex);
      const { lastIndexPosition } = findLastMentionString(searchText);
      const replaceText = inputText.slice(caretIndex - lastIndexPosition, caretIndex);

      const {
        node: currentNode,
        startingIndex,
        index,
      } = getNodeAtPosition(currentMessageInputRef.childNodes, caretIndex - 1);

      if (currentNode) {
        const currentNodeValue = currentNode.textContent;

        if (currentNodeValue?.search(replaceText) !== -1 && currentNodeValue) {
          const { lastIndexPosition: nodeLastIndexPosition } = findLastMentionString(searchText);

          const firstNode = document.createTextNode(
            currentNodeValue.slice(0, caretIndex - startingIndex - nodeLastIndexPosition)
          );
          const parentSpan = createMentionNode(entity);
          const space = document.createTextNode('\xA0');
          const lastNode = document.createTextNode(
            currentNodeValue.slice(caretIndex - startingIndex)
          );

          insertNewNodeAndSetCaret(
            firstNode,
            parentSpan,
            space,
            lastNode,
            index,
            currentMessageInputRef
          );
        }
        setBody(currentMessageInputRef?.innerHTML);
        dispatch(setMessageBodyInputFocus(true));
        logPendoTypedMention();
      }
    }
  };

  const handlePasteCallback = useRef((event: ClipboardEvent) =>
    handlePaste(addAttachments, event, window, insertAtCursor)
  );

  useEffect(() => {
    const currentMessageInputRef = inputRef.current;
    const { current: callback } = handlePasteCallback;
    currentMessageInputRef?.addEventListener('paste', callback);
    return () => {
      currentMessageInputRef?.removeEventListener('paste', callback);
    };
  }, [inputRef, addAttachments]);

  useEffect(() => {
    if (inputRef.current && cursorPosition !== undefined) {
      const range = document.createRange();
      const sel = window.getSelection();
      if (cursorPosition.node) {
        range.selectNodeContents(cursorPosition.node || inputRef.current);
        range.collapse(false);
      } else {
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
      }
      sel?.removeAllRanges();
      sel?.addRange(range);
      inputRef.current.focus();
      setCursorPosition(undefined);
    }
  }, [inputRef, cursorPosition]);

  const onChange = useCallback(
    (event: ContentEditableEvent) => {
      const body = event.target.value;

      setBody(body);
      if (body) {
        onBodyChange?.();
        onTyping?.();

        if (isCommandBotEnabled || isGiphyBotEnabled) {
          shouldRunCommandBot({
            bodyText: body,
            isGiphyBotEnabled,
            isCommandBotEnabled,
            isCommandEditorOpen,
            setState: setCommandInfo,
            toggleCommandEditor,
          });
        }
      } else {
        if (isCommandEditorOpen) toggleCommandEditor();

        if (mentionMemberFloatingModal) {
          setMentionMemberFloatingModal(false);
        }
      }
    },
    [
      setBody,
      onBodyChange,
      onTyping,
      isCommandBotEnabled,
      isGiphyBotEnabled,
      isCommandEditorOpen,
      setCommandInfo,
      toggleCommandEditor,
      mentionMemberFloatingModal,
    ]
  );

  const getMentionMembersData = () => {
    const input = inputRef.current?.childNodes;
    const inputText = inputRef.current?.innerText;
    let individualMentionsCount = 0,
      roleMentionsCount = 0,
      startIndex = 0;

    const mentionMembersPayload = Object.values(input || []).reduce((acc, child: ChildNode) => {
      if (child?.firstChild && child.firstChild.nodeName === 'SPAN') {
        const getFirstChildElement = child.firstChild.nextSibling?.firstChild as HTMLElement;
        const getParentChildElement = child.firstChild.nextSibling as HTMLElement;
        const userDisplayName = getFirstChildElement.innerText;
        const mentionEntityType = getParentChildElement.getAttribute('mentionentitytype');
        const mentionId = getParentChildElement.getAttribute('mentionid');
        startIndex = inputText?.indexOf(userDisplayName, startIndex) || 0;
        const endIndex = startIndex + userDisplayName.length;
        if (mentionEntityType === 'role') {
          roleMentionsCount++;
        } else {
          individualMentionsCount++;
        }
        acc.push({
          entity_type: mentionEntityType || '',
          entity_id: mentionId || '',
          start: startIndex,
          end: endIndex,
        });
        startIndex += userDisplayName.length - 1;
      } else {
        const getChildText = child as Text;
        startIndex += getChildText.length || 0;
      }

      return acc;
    }, [] as MentionsPayloadProps[]);

    return {
      individualMentionsCount,
      mentionMembersPayload,
      messageEditableBody: inputText,
      roleMentionsCount,
    };
  };

  const trimMessageBody = useCallback(() => {
    let currentBodyTrim = body.trim();

    currentBodyTrim = currentBodyTrim.replace(/\n/g, '<br>');

    while (currentBodyTrim !== currentBodyTrim.replace(/ +|\s+|&nbsp;/g, ' ')) {
      currentBodyTrim = currentBodyTrim.replace(/ +|\s+|&nbsp;/g, ' ');
    }

    while (currentBodyTrim !== currentBodyTrim.replace(/^ +|^<br>+/g, '')) {
      currentBodyTrim = currentBodyTrim.replace(/^ +|^<br>+/g, '');
    }

    return currentBodyTrim;
  }, [body]);

  useImperativeHandle(ref, () => ({
    insertAtCursor,
    insertMentionAtCursor,
    insertMentionMemberSelector,
    getMentionMembersData,
    trimMessageBody,
  }));

  return (
    <div
      className={classNames(classes(), className)}
      style={{ flexDirection }}
      ref={messageBodyContentEditableInputRef}
    >
      {richTextFormat && (
        <FormattingButtonsContentEditable insertElementsAtCursor={insertElementsAtCursor} />
      )}
      {mentionMemberFloatingModal && currentUserId && (
        <MentionMemberFloatingModal
          currentUserId={currentUserId}
          currentConversation={currentConversation}
          leftModalPosition={leftModalPosition}
          isComposing={isComposing}
          insertEmptySpan={insertEmptySpan}
          selectEntity={insertOneMentionAtCursor}
          selectedRecipients={selectedRecipients}
          setMentionMemberFloatingModal={setMentionMemberFloatingModal}
          filter={mentionFilter}
          ref={mentionMemberFloatingModalRef}
        />
      )}
      {useMemo(
        () => (
          <ContentEditable
            className={classes('input')}
            aria-label={'Message Input Box'}
            html={body}
            innerRef={inputRef}
            onChange={onChange}
            onClick={onClick}
            //@ts-expect-error lib has no type but it passes down
            placeholder="Type message here"
            style={{ borderColor }}
            tabIndex={tabIndex}
          />
        ),
        [body, borderColor, onChange, onClick, tabIndex]
      )}
    </div>
  );
};

export default forwardRef<InsertAtCursorHandle, MessageBodyContentEditableInputProps>(
  MessageBodyContentEditableInput
);
