import EventEmitter from 'events';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { isEqual, pick } from 'lodash';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { AlertCard } from '../../widgets/messenger/components/AlertCard';
import { ReduxState } from '../../redux-stores/index';
import getLimitedMessages from './utils/getLimitedMessages';
import { DotsIndicator, GroupedMessagesList, ScrollerStickToBottom } from './';
import type { Conversation, Group, Message } from 'types';
import waitForElement from 'common/utils/waitForElement';

const classes = BEM.with('ConversationMessages');

const AUTOSCROLL_AFTER_MOUNT_MS = 100;
const NEW_MESSAGE_INDICATOR = 'NewMessageIndicator';
const THUMB_MARGIN_TOP = 16;

type MobxProps = {
  allowRolesPerformance: boolean;
  condensedReplays: boolean;
  currentConversation: Conversation | null;
  currentConversationInitialUnreadCount: number;
  currentMessageMultiSelectOption: string;
  currentPreviewConversation: Conversation | null;
  currentUserId: string | null;
  events: EventEmitter;
  fetchTimeline: (
    conversationId: string,
    {
      anchorPoint,
      continuation,
      maxItems,
    }: {
      anchorPoint?: unknown;
      continuation?: unknown;
      maxItems?: unknown;
    }
  ) => Promise<number | undefined>;
  fetchTimelineSelectedConversation: (
    conversation: Conversation,
    {
      anchorPoint,
      markAsRead,
    }?: {
      anchorPoint?: string | undefined;
      markAsRead?: boolean | undefined;
    }
  ) => Promise<void>;
  hasElement: (elementName: string) => boolean;
  hasMoreTimelineToShowBottom: (numOfMessagesToShow: number) => boolean;
  hasMoreTimelineToShowTop: (numOfMessagesToShow: number) => boolean;
  isGroupAlertsVCAllowed: boolean;
  isLoadingConversation: boolean;
  isProviderNetwork: boolean;
  messagesToShow: number;
  resetMessagesToShow: () => void;
  scrollOffset: number;
  scrollToElement: (elementName: string, scrollOptions?: unknown) => void;
  selectedMessageInitialOffset: number;
  setMessagesToShow: (messagesToShow: number) => void;
  setScrollbars: (ref: unknown) => void;
  setShouldShowLimitedMessages: (shouldShowLimitedMessages: boolean) => void;
  setStickyFirstUnreadMessage: (firstUnreadMessage: Message | null) => void;
  shouldMarkConversationAsRead: boolean;
  shouldShowLimitedMessages: boolean;
  showLoadingDots: boolean;
  startSentryTransaction: (name: string) => Sentry.Span | never;
  stickyFirstUnreadMessage: Message;
  setKeywordSearchScrollTarget: (element: HTMLElement | null) => void;
};

type ConversationMessagesProps = {
  counterParty: Group;
  firstUnreadMessage: Message;
  lastMessage: Message;
  markConversationAsRead: (options: unknown) => Promise<void>;
  messages: Message[];
  isKeywordSearchModeOn: boolean;
  selectedMessageId: string;
};

type CombinedProps = ConversationMessagesProps & MobxProps;

class ConversationMessages extends Component<CombinedProps> {
  _addedContentToTop = false;
  _differentLastMessage = false;
  _enableTransition = false;
  _enableTransitionTimeout: number | null = null;
  _firstRenderLastMessage: unknown | null = null;
  _isFirstRender = true;
  _lastMessage: Message | null = null;
  _lastMessageCount = 0;
  _lastMessagesToShow: number | null = null;
  _lostStickiness = false;
  _messagesLimitedTimeout: number | null = null;
  _shouldLoseStickiness = false;
  _shouldMarkAsRead = false;
  _wasLoadingConversation = false;
  _wasScrolledToBottomBeforeRender = false;

  scroller: ScrollerStickToBottom | null = null;

  state = {
    numMessagesToShowTop: this.props.messagesToShow,
    numMessagesToShowBottom: this.props.messagesToShow,
  };

  componentDidMount() {
    const { events, setStickyFirstUnreadMessage, stickyFirstUnreadMessage } = this.props;
    const { firstUnreadMessage } = this.props;

    events.on('clearNewMessagesIndicator', this._clearNewMessagesIndicator);
    events.on('allImagesLoaded', this._onImagesLoaded);

    if (firstUnreadMessage && !stickyFirstUnreadMessage) {
      setStickyFirstUnreadMessage(firstUnreadMessage);
    }

    this._enableTransitionTimeout = setTimeout(() => {
      this._enableTransition = true;
      this._shouldMarkAsRead = true;
    }, AUTOSCROLL_AFTER_MOUNT_MS);
  }

  componentWillUnmount() {
    const { events, resetMessagesToShow } = this.props;
    events.removeListener('clearNewMessagesIndicator', this._clearNewMessagesIndicator);
    events.removeListener('allImagesLoaded', this._onImagesLoaded);

    resetMessagesToShow();
    this._enableTransitionTimeout && clearTimeout(this._enableTransitionTimeout);
    this._messagesLimitedTimeout && window.cancelAnimationFrame(this._messagesLimitedTimeout);
  }

  getKeywordSearchScrollTarget = async () => {
    if (!this.props.selectedMessageId) {
      console.warn('No selected message ID provided for scroll target');
      return;
    }

    try {
      const messageElement = await waitForElement(
        `[data-message-id="${this.props.selectedMessageId}"]`,
        15000,
        3
      );

      if (messageElement) {
        this.props.setKeywordSearchScrollTarget(messageElement);
      } else {
        console.warn(`Message element with ID ${this.props.selectedMessageId} not found`);
      }
    } catch (error) {
      console.error('Error waiting for element:', error);
    }
  };

  componentDidUpdate(prevProps: CombinedProps) {
    this._scrollOnUpdate(prevProps);
    const scrollProps = ['selectedMessageId', 'isLoadingConversation', 'showLoadingDots'];

    if (
      !isEqual(pick(this.props, scrollProps), pick(prevProps, scrollProps)) &&
      this.props.isKeywordSearchModeOn &&
      !this.props.isLoadingConversation &&
      !this.props.showLoadingDots &&
      this.props.selectedMessageId
    ) {
      this.getKeywordSearchScrollTarget();
    }

    if (
      this.scroller &&
      (prevProps.showLoadingDots || prevProps.isLoadingConversation) &&
      !(this.props.showLoadingDots || this.props.isLoadingConversation)
    ) {
      const element = ReactDOM.findDOMNode(this.scroller) as HTMLElement;
      if (element) element.focus();
    }
  }

  render() {
    const {
      allowRolesPerformance,
      condensedReplays,
      counterParty,
      currentConversationInitialUnreadCount,
      hasMoreTimelineToShowBottom,
      hasMoreTimelineToShowTop,
      isGroupAlertsVCAllowed,
      isLoadingConversation,
      isProviderNetwork,
      lastMessage,
      messages,
      messagesToShow,
      scrollOffset,
      shouldShowLimitedMessages,
      showLoadingDots,
      stickyFirstUnreadMessage,
    } = this.props;
    const { numMessagesToShowTop, numMessagesToShowBottom } = this.state;
    const { patientDetails = {} } = counterParty || {};
    const { smsOptedOut = false } = patientDetails || {};
    let { firstUnreadMessage } = this.props;
    let itemsEstimateBottom = 0;
    let itemsEstimateTop = 0;
    let shouldFetchMoreTop = false;

    const conversation = this._getCurrentConversation();
    const showLoadingOverlay = showLoadingDots || isLoadingConversation;

    if (conversation) {
      const { allowedSenders, higherContinuation, lowerContinuation } = conversation;
      itemsEstimateBottom = higherContinuation.itemsEstimate;
      itemsEstimateTop = lowerContinuation.itemsEstimate;

      const isRoleFound = allowedSenders.some((entity) => entity.$entityType === 'role');

      const isFullyFetchedProp = isRoleFound ? 'fullyFetchedRole' : 'fullyFetchedUser';

      if (!condensedReplays && allowRolesPerformance) {
        shouldFetchMoreTop = !lowerContinuation[isFullyFetchedProp];
      }
    }

    if (stickyFirstUnreadMessage) {
      firstUnreadMessage = stickyFirstUnreadMessage;
    }

    const messageCount = messages.length;
    const isRole = !!counterParty && counterParty.p2pRecipientType === 'role';
    const isEscalationGroup = !!(
      counterParty &&
      counterParty.groupType === 'ESCALATION' &&
      counterParty.escalationExecution
    );
    const headerType = isEscalationGroup
      ? 'escalationGroup'
      : isRole
      ? 'role'
      : !isProviderNetwork
      ? 'patient'
      : 'normal';
    const moreContentAvailableBottom =
      itemsEstimateBottom > 0 ||
      (shouldShowLimitedMessages && hasMoreTimelineToShowBottom(numMessagesToShowBottom));
    const moreContentAvailableTop =
      itemsEstimateTop > 0 ||
      (shouldShowLimitedMessages && hasMoreTimelineToShowTop(numMessagesToShowTop)) ||
      shouldFetchMoreTop;

    let shownMessages;
    if (shouldShowLimitedMessages) {
      const sentryTransaction = this.props.startSentryTransaction(
        'ConversationMessages.getLimitedMessages'
      );

      shownMessages = getLimitedMessages(messages, {
        hasUnreadMessages: !!stickyFirstUnreadMessage,
        numMessagesToShowBottom,
        numMessagesToShowTop,
        unreadCount: currentConversationInitialUnreadCount,
        targetMessageId: this.props.isKeywordSearchModeOn ? this.props.selectedMessageId : '',
      });

      sentryTransaction?.end();
    } else {
      shownMessages = messages;
    }

    const isSelectedMessageVisible = shownMessages.some(
      (msg) => msg.serverId === this.props.selectedMessageId
    );

    if (!isSelectedMessageVisible && this.props.isKeywordSearchModeOn) {
      this._increaseShownMessages('TOP');
    }

    const differentLastMessage =
      (!this._lastMessage && !!lastMessage) || this._lastMessage !== lastMessage;
    const enableTransition = this._enableTransition && differentLastMessage;

    if (this._isFirstRender) {
      this._firstRenderLastMessage = lastMessage;
    }

    this._differentLastMessage = differentLastMessage;
    this._isFirstRender = false;
    this._lastMessage = lastMessage;
    this._lastMessageCount = messageCount;
    this._lastMessagesToShow = messagesToShow;
    this._lostStickiness = this._shouldLoseStickiness;
    this._shouldLoseStickiness = false;
    this._wasScrolledToBottomBeforeRender = this.isScrolledToBottom();

    const shouldShowGroupAlert =
      isGroupAlertsVCAllowed &&
      conversation?.featureService === 'group_alerts' &&
      conversation?.counterParty?.alertMessage;

    return (
      <div className={classes({ headerType, smsOptedOut })}>
        {shouldShowGroupAlert && (
          <div className={classes('alert-card-wrapper')}>
            <AlertCard
              message={conversation.counterParty.alertMessage}
              numLinesToClipContentAt={2}
            />
          </div>
        )}
        <div className={classes('message-list-wrapper')}>
          <ScrollerStickToBottom
            key={counterParty ? counterParty.id : 'new-message'}
            moreContentAvailableBottom={moreContentAvailableBottom}
            moreContentAvailableTop={moreContentAvailableTop}
            onScroll={this._onScroll}
            ref={this._setScroller}
            scrollOffset={scrollOffset}
            showMoreContent={this._increaseShownMessages}
            thumbMarginTop={THUMB_MARGIN_TOP}
            preventAutoScroll={!!(this.props.isKeywordSearchModeOn && this.props.selectedMessageId)}
          >
            <GroupedMessagesList
              enableTransition={enableTransition}
              firstUnreadMessage={firstUnreadMessage}
              key={counterParty ? counterParty.id : 'new-message'}
              messages={shownMessages}
              showLoaderBottom={moreContentAvailableBottom}
              showLoaderTop={moreContentAvailableTop}
            />
          </ScrollerStickToBottom>
        </div>
        {showLoadingOverlay && (
          <div className={classes('loading-overlay')}>
            {showLoadingDots && (
              <div className={classes('dots-container')}>
                <DotsIndicator color={'#969696'} size={13} />
                <div className={classes('dots-text')}>Loading conversation</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  _scrollOnUpdate = (prevProps: CombinedProps) => {
    const {
      currentMessageMultiSelectOption,
      currentUserId,
      hasElement,
      isLoadingConversation,
      selectedMessageInitialOffset,
      scrollToElement,
      stickyFirstUnreadMessage,
    } = this.props;

    if (prevProps.currentMessageMultiSelectOption !== currentMessageMultiSelectOption) {
      scrollToElement('firstMessageSelected', {
        customOffset: selectedMessageInitialOffset - 135,
      });
      return;
    }

    const wasLoadingConversation = this._wasLoadingConversation;

    if (wasLoadingConversation && !isLoadingConversation) {
      if (hasElement(NEW_MESSAGE_INDICATOR)) {
        scrollToElement(NEW_MESSAGE_INDICATOR);
      } else {
        this._scrollToBottom();
      }
    } else if (
      this._lostStickiness ||
      (this._wasScrolledToBottomBeforeRender && !stickyFirstUnreadMessage)
    ) {
      this._scrollToBottom();
    } else if (
      this._lastMessage &&
      this._differentLastMessage &&
      this._lastMessage.senderId === currentUserId
    ) {
      this._scrollToBottom();
    }

    this._lostStickiness = false;
    this._wasLoadingConversation = isLoadingConversation;
    this._wasScrolledToBottomBeforeRender = false;
  };

  _markNewMessagesAsRead = ({ isScrolledToBottom }: { isScrolledToBottom?: boolean }) => {
    const { markConversationAsRead, shouldMarkConversationAsRead } = this.props;
    const conversation = this._getCurrentConversation();
    if (!conversation) return;

    if (
      shouldMarkConversationAsRead &&
      markConversationAsRead &&
      this._shouldMarkAsRead &&
      isScrolledToBottom &&
      document.hasFocus()
    ) {
      markConversationAsRead(conversation);
    }
  };

  _setScroller = (ref: ScrollerStickToBottom | null): void => {
    this.scroller = ref;
    this.props.setScrollbars(ref);
  };

  _onScroll = ({
    direction,
    isScrolledToBottom,
    isUserScroll,
  }: {
    direction?: unknown;
    isScrolledToBottom?: boolean;
    isUserScroll?: boolean;
  }) => {
    if (isUserScroll && direction === 'DOWN') {
      this._markNewMessagesAsRead({ isScrolledToBottom });
    } else if (isUserScroll && direction === 'UP') {
      this._firstRenderLastMessage = null;
    }
  };

  _increaseShownMessages = async (direction: 'TOP' | 'BOTTOM') => {
    const skipped = await new Promise((resolve) => {
      if (this._messagesLimitedTimeout !== null) return resolve(true);
      resolve(false);
    });

    if (skipped) return;
    this._messagesLimitedTimeout = null;

    const { shouldShowLimitedMessages, setShouldShowLimitedMessages } = this.props;

    if (shouldShowLimitedMessages) {
      const { hasMoreTimelineToShowBottom, hasMoreTimelineToShowTop, messagesToShow } = this.props;
      const { numMessagesToShowTop, numMessagesToShowBottom } = this.state;
      const hasMoreTimelineTop = hasMoreTimelineToShowTop(numMessagesToShowTop);
      const hasMoreTimelineBottom = hasMoreTimelineToShowBottom(numMessagesToShowBottom);

      if (direction === 'TOP' && hasMoreTimelineTop) {
        return new Promise<void>((resolve) => {
          this.setState({ numMessagesToShowTop: numMessagesToShowTop + messagesToShow });
          resolve();
        });
      } else if (direction === 'BOTTOM' && hasMoreTimelineBottom) {
        return new Promise<void>((resolve) => {
          this.setState({ numMessagesToShowBottom: numMessagesToShowBottom + messagesToShow });
          resolve();
        });
      }

      if (!hasMoreTimelineTop && !hasMoreTimelineBottom) {
        setShouldShowLimitedMessages(false);
      }
    }

    const addedMessages =
      direction === 'TOP' ? await this._fetchMessagesTop() : await this._fetchMessagesBottom();

    return new Promise<void>((resolve) => {
      const { messagesToShow, setMessagesToShow } = this.props;
      setMessagesToShow(messagesToShow + addedMessages);
      resolve();
    });
  };

  _clearNewMessagesIndicator = (conversation: Conversation) => {
    const currentConversation = this._getCurrentConversation();
    if (currentConversation !== conversation) return;

    const { setStickyFirstUnreadMessage, stickyFirstUnreadMessage } = this.props;

    if (stickyFirstUnreadMessage) {
      this._shouldLoseStickiness = true;
      setStickyFirstUnreadMessage(null);
    }
  };

  isScrolledToBottom = () => {
    return this.scroller ? this.scroller.isScrolledToBottom() : false;
  };

  _scrollToBottom = () => {
    this.scroller && this.scroller.scrollToBottom();
  };

  _getCurrentConversation = () => {
    const { currentConversation, currentPreviewConversation } = this.props;
    return currentConversation || currentPreviewConversation;
  };

  _fetchMessagesTop = async () => {
    const conversation = this._getCurrentConversation();
    if (!conversation) return 0;

    const continuation = conversation.lowerContinuation.continuation;

    const addedMessages = await this._fetchTimeline({
      anchorPoint: 'CONTINUATION',
      continuation,
    });

    this._addedContentToTop = addedMessages > 0;

    return addedMessages;
  };

  _fetchMessagesBottom = async () => {
    const conversation = this._getCurrentConversation();
    if (!conversation) return 0;

    const continuation = conversation.higherContinuation.continuation;

    const addedMessages = await this._fetchTimeline({
      anchorPoint: 'CONTINUATION',
      continuation,
    });

    return addedMessages;
  };

  _fetchTimeline = async ({
    anchorPoint,
    continuation,
  }: {
    anchorPoint: string;
    continuation: unknown;
  }) => {
    const { fetchTimeline } = this.props;

    const conversation = this._getCurrentConversation();
    if (!conversation) return 0;

    const addedMessages = await fetchTimeline(conversation.id, {
      anchorPoint,
      continuation,
    });

    return addedMessages ?? 0;
  };

  _onImagesLoaded = () => {
    this._addedContentToTop = true;
    this.forceUpdate();
  };
}

const mapStateToProps = (state: ReduxState) => ({
  isKeywordSearchModeOn: state.keywordSearch.isKeywordSearchModeOn,
  selectedMessageId: state.keywordSearch.selectedMessageId,
});

export default connect(mapStateToProps)(
  mobxInjectSelect<ConversationMessagesProps, MobxProps>({
    composeMessageStore: ['currentPreviewConversation'],
    conversationStore: [
      'currentConversation',
      'currentConversationInitialUnreadCount',
      'events',
      'fetchTimeline',
      'fetchTimelineSelectedConversation',
      'hasElement',
      'hasMoreTimelineToShowBottom',
      'hasMoreTimelineToShowTop',
      'isLoadingConversation',
      'messagesToShow',
      'resetMessagesToShow',
      'scrollOffset',
      'scrollToElement',
      'selectedMessageInitialOffset',
      'setMessagesToShow',
      'setScrollbars',
      'setShouldShowLimitedMessages',
      'setStickyFirstUnreadMessage',
      'shouldMarkConversationAsRead',
      'shouldShowLimitedMessages',
      'showLoadingDots',
      'stickyFirstUnreadMessage',
      'setKeywordSearchScrollTarget',
    ],
    messengerStore: [
      'allowRolesPerformance',
      'condensedReplays',
      'currentMessageMultiSelectOption',
      'isGroupAlertsVCAllowed',
    ],
    networkStore: ['isProviderNetwork'],
    sessionStore: ['currentUserId'],
    trackerStore: ['startSentryTransaction'],
  })(ConversationMessages)
);
