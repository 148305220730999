import { Message } from '../../../types';

const getLimitedMessages = (
  originalMessages: Message[],
  {
    hasUnreadMessages = false,
    numMessagesToShowBottom = 0,
    numMessagesToShowTop = 0,
    unreadCount = 0,
    targetMessageId = '',
  } = {}
) => {
  let limitedMessages;

  if (targetMessageId) {
    const targetIdx = originalMessages.findIndex((msg) => msg.serverId === targetMessageId);

    if (targetIdx !== -1) {
      limitedMessages = originalMessages.slice(Math.max(0, targetIdx - numMessagesToShowTop));
    } else {
      limitedMessages = originalMessages;
    }
  } else if (hasUnreadMessages) {
    const firstUnreadIdx = originalMessages.length - unreadCount;
    limitedMessages = originalMessages.slice(
      Math.max(0, firstUnreadIdx - numMessagesToShowTop),
      firstUnreadIdx + numMessagesToShowBottom
    );
  } else {
    limitedMessages = originalMessages.slice(
      Math.max(0, originalMessages.length - numMessagesToShowTop)
    );
  }

  return limitedMessages;
};

export default getLimitedMessages;
