import { useEffect, useRef } from 'react';
import KeywordMessageBubble from './KeywordMessageBubble';
import { getStoreModel } from './helper';
import BEM from 'common/bem';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { useSearchQuery } from 'redux-stores/KeywordSearch/keywordSearchPrimaryLevelApi';
import { useRTKQueryInfiniteResults } from 'common/hooks/useRTKQueryInfiniteResults';
import { User, Group } from 'types';

const classes = BEM.with('KeywordSearchSideBar');

const { setSelectedConversationId, setIsFetching } = actions;

interface ConversationMetadata {
  group_id: string;
  peer_token: string;
  is_dl_list: boolean;
}

type MobxProps = {
  currentUserId: string;
  currentOrganizationId: string;
  myRoleIds: string[];
  getEntityData: (entityType: string, id: string) => User | Group;
  trackingInboxSearchPendo: (searchLocation: string) => void;
};

const KeywordSearchFirstLevel = ({
  currentUserId,
  myRoleIds,
  getEntityData,
  currentOrganizationId,
  trackingInboxSearchPendo,
}: MobxProps) => {
  const dispatch = useAppDispatch();
  const keywordSearchRef = useRef<HTMLDivElement>(null);
  const prevKeywordSearchTerm = useRef<string>('');

  const { selectedConversationId, keywordSearchTerm } = useAppSelector(
    (state) => state.keywordSearch
  );

  const handleSeeAllClick = (conversationId: string) => {
    dispatch(setSelectedConversationId(conversationId));
  };

  const queryArguments = {
    searchValue: keywordSearchTerm,
    orgId: currentOrganizationId,
  };

  const { results, isFetching, response } = useRTKQueryInfiniteResults(
    useSearchQuery,
    {
      args: queryArguments,
    },
    { ref: keywordSearchRef }
  );
  const { continuation } = response?.metadata || {};

  useEffect(() => {
    if (prevKeywordSearchTerm.current === keywordSearchTerm) {
      const hasResults = (results && results?.length > 0) || false;
      if (!isFetching && hasResults) {
        trackingInboxSearchPendo('inbox search');
      }
    } else {
      prevKeywordSearchTerm.current = keywordSearchTerm;
    }
  }, [isFetching, keywordSearchTerm, results, trackingInboxSearchPendo]);

  useEffect(() => {
    if (isFetching && continuation === undefined) {
      dispatch(setIsFetching(true));
    } else {
      dispatch(setIsFetching(false));
    }
  }, [isFetching, dispatch, continuation]);

  function getConversationName(conversationMetadata: ConversationMetadata) {
    const { group_id, peer_token, is_dl_list } = conversationMetadata || {};
    const id = group_id || peer_token;

    if (!id) return 'Error: ID not found';

    const modelType = group_id ? 'group' : is_dl_list ? 'distributionList' : 'user';

    const entity = getStoreModel(modelType, id, getEntityData);

    return entity?.displayName || 'Error: Conversation name not found';
  }

  const hasSelectedConversation = Boolean(selectedConversationId && selectedConversationId.trim());

  return (
    <div
      className={classes('results-container', {
        block: !hasSelectedConversation,
        none: hasSelectedConversation,
      })}
      ref={keywordSearchRef}
    >
      {results?.map(({ entity }) => (
        <div className={classes('conversation-result')} key={entity?.conversation_id}>
          <div className={classes('conversation-header')}>
            <div className={classes('conversation-name')}>
              {getConversationName(entity?.conversation_metadata)}
            </div>
            {entity?.total_hits_value > 2 && (
              <div
                className={classes('seeAll-label')}
                onClick={() => handleSeeAllClick(entity?.conversation_id)}
              >
                See All
              </div>
            )}
          </div>
          <div className={classes('message-results')}>
            {entity?.values.map(
              ({
                body,
                created_time_server,
                highlight,
                sender_token,
                is_group,
                message_id,
                sort_number,
              }) => {
                return (
                  <KeywordMessageBubble
                    key={message_id}
                    body={body}
                    conversationMetadata={entity.conversation_metadata}
                    createdTime={created_time_server}
                    isGroup={is_group}
                    currentUserId={currentUserId}
                    myRoleIds={myRoleIds}
                    senderToken={sender_token}
                    highlight={highlight}
                    messageId={message_id}
                    sort_number={sort_number}
                  />
                );
              }
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default mobxInjectSelect<{}, MobxProps>({
  roleStore: ['myRoleIds'],
  sessionStore: ['currentUserId'],
  entityStore: ['getEntityData'],
  messengerStore: ['currentOrganizationId'],
  trackerStore: ['trackingInboxSearchPendo'],
})(KeywordSearchFirstLevel);
