import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { isUndefined, uniq } from 'lodash';
import { ReduxState } from '../..';
import { PagerNumber, Role } from '../../../types';
import { CollabRolesState, ScheduleUploadResponse } from '../../../types/Collaboration';
import * as rolesThunk from './thunk';

const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    activeRoleIds: [],
    awayMessages: {},
    checkedRoleIds: [],
    integrationIds: [],
    isAreaCodeError: false,
    isGettingPagerNumbers: false,
    isPagerError: false,
    pagerNumbers: [],
    roleOwnershipLoadingTokens: [],
    savedRoleIds: [],
  } as CollabRolesState,
  reducers: {
    clearRoleScheduleUploadResponse: (state) => {
      state.scheduleUploadResponse = undefined;
    },

    setAreaCodeError: (state, action: PayloadAction<boolean>) => {
      state.isAreaCodeError = action.payload;
    },

    setIntegrationIds: (state, action: PayloadAction<string[]>) => {
      state.integrationIds = action.payload;
    },

    setIsGettingPagerNumbers: (state, action: PayloadAction<boolean>) => {
      state.isGettingPagerNumbers = action.payload;
    },

    setAwayMessages: (state, action: PayloadAction<{ id: string; awayMessage: string }>) => {
      state.awayMessages[action.payload.id] = action.payload.awayMessage;
    },

    setMyRoles: (
      state,
      action: PayloadAction<{ activeRoleIds?: string[]; savedRoleIds?: string[] }>
    ) => {
      const { activeRoleIds, savedRoleIds } = action.payload;
      if (!isUndefined(savedRoleIds)) state.savedRoleIds = savedRoleIds;
      if (!isUndefined(activeRoleIds)) state.activeRoleIds = activeRoleIds;
    },

    setPagerError: (state, action: PayloadAction<boolean>) => {
      state.isPagerError = action.payload;
    },

    setPagerNumbers: (state, action: PayloadAction<PagerNumber[]>) => {
      state.pagerNumbers = action.payload;
    },

    setRoleOwnershipLoading: (state, action: PayloadAction<{ isLoading: boolean; id: string }>) => {
      let newList = state.roleOwnershipLoadingTokens || [];
      if (action.payload.isLoading) {
        newList.push(action.payload.id);
      } else {
        newList = newList.filter((s) => s !== action.payload.id);
      }
      state.roleOwnershipLoadingTokens = newList;
    },

    setRoleScheduleUploadResponse: (state, action: PayloadAction<ScheduleUploadResponse>) => {
      state.scheduleUploadResponse = action.payload;
    },
  },
});

const defaultNewRole: Role = {
  $entityType: 'role',
  description: '',
  displayName: '',
  id: '',
  orgPagers: [],
  metadata: {
    escalation_policy: {},
    exclude_private_group: false,
    no_owners_message: '',
    open_assignment: true,
    owner_required: false,
    replay_history: true,
    role_transition: false,
    room_assignment_id: '',
    tag_color: '',
    tag_hex: '',
    tag_id: '',
    tag_name: '',
  },
  isRoleBot: true,
  botRole: { memberIds: [''] },
};

export { defaultNewRole, rolesThunk, rolesSlice };

export const selectActiveRoleIds = (state: ReduxState) => state.roles.activeRoleIds;
export const selectSavedRoleIds = (state: ReduxState) => state.roles.savedRoleIds;
export const selectMyRolesCount = createSelector(
  [selectActiveRoleIds, selectSavedRoleIds],
  (activeRoleIds, savedRoleIds) => uniq([...activeRoleIds, ...savedRoleIds]).length
);
