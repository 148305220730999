export default function waitForElement(
  selector: string,
  timeout = 15000,
  retries = 3
): Promise<HTMLElement | null> {
  return new Promise((resolve) => {
    let attempts = 0;

    const tryFindElement = () => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector) as HTMLElement);
      }

      attempts++;
      if (attempts >= retries) {
        console.warn(`Element ${selector} not found after ${retries} attempts`);
        return resolve(null);
      }

      const observer = new MutationObserver((mutations) => {
        const clearObserver = setTimeout(() => {
          observer.disconnect();
          tryFindElement();
        }, timeout / retries);

        if (document.querySelector(selector)) {
          clearTimeout(clearObserver);
          observer.disconnect();
          resolve(document.querySelector(selector) as HTMLElement);
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    };

    tryFindElement();
  });
}
