interface ScrollOptions {
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  inline?: ScrollLogicalPosition;
  maxRetries?: number;
  retryDelay?: number;
}

export default function scrollIntoView(
  element: HTMLElement | null,
  options: ScrollOptions = {}
): Promise<boolean> {
  const {
    behavior = 'smooth',
    block = 'center',
    inline = 'nearest',
    maxRetries = 3,
    retryDelay = 100,
  } = options;

  if (!element) {
    console.warn('No element provided for scrolling');
    return Promise.resolve(false);
  }

  if (!document.contains(element)) {
    console.warn('Element is not in the DOM');
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    let attempts = 0;

    const attemptScroll = () => {
      try {
        if (!document.contains(element)) {
          console.warn('Element was removed from DOM during scroll attempt');
          return false;
        }

        if (element && element.scrollIntoView) {
          const rect = element.getBoundingClientRect();
          const isVisible = rect.width > 0 && rect.height > 0;

          if (!isVisible) {
            return false;
          }

          element.scrollIntoView({
            behavior,
            block,
            inline,
          });

          const afterRect = element.getBoundingClientRect();
          const isInViewport = afterRect.top >= 0 && afterRect.bottom <= window.innerHeight;

          return isInViewport;
        }
      } catch (error) {
        console.error('Error scrolling to element:', error);
      }
      return false;
    };

    const retryScroll = () => {
      if (attempts >= maxRetries) {
        console.warn(`Failed to scroll after ${maxRetries} attempts`);
        resolve(false);
        return;
      }

      attempts++;
      setTimeout(() => {
        if (!attemptScroll()) {
          retryScroll();
        } else {
          resolve(true);
        }
      }, retryDelay);
    };

    // Initial attempt
    if (!attemptScroll()) {
      retryScroll();
    } else {
      resolve(true);
    }
  });
}
