import BaseAPI, { ROUTES, VERSIONS, VersionNumber } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { SEARCH_QUERY } = ROUTES;

type ServerEntity = {
  conversation_id?: string;
  members?: unknown[];
  metadata?: { first_hit: number; total_hits: number };
  token?: string;
};

type Result = {
  conversation_id?: string;
  type: string;
  entity: ServerEntity;
  organization_id: string;
};

type LegacyResponse = {
  metadata: { continuation?: string | null; first_hit: number; total_hits: number };
  results: Result[];
};

export type SearchParityResponse = {
  metadata: {
    continuation?: string | null;
    entity_order?: string[];
    first_hit: number;
    total_hits: number;
  };
  results: {
    entity_type: string;
    values: Result[];
  }[];
};

export type KeywordSearchSecondLevelResultValue = {
  conversation_id: string;
  conversation_metadata: {
    group_id: string;
    is_dl_list: boolean;
    peer_token: string;
  };
  entity: {
    attachment_name: string;
    body: string;
    created_time_server: string;
    highlight: { body: string; attachment_name: string };
    is_group: boolean;
    message_id: string;
    recipient_token: string;
    sender_avatar: string;
    sender_display_name: string;
    sender_token: string;
    sort_number: number;
    ttl: number;
  };
  organization_id: string;
  type: string;
};
export type KeywordSearchSecondLevelResult = {
  entity_type: string;
  values: KeywordSearchSecondLevelResultValue[];
};

export type KeywordSearchGeneralResult = {
  entity: {
    conversation_id: string;
    total_hits_value: number;
    conversation_metadata: {
      peer_token: string;
      group_id: string;
      is_dl_list: boolean;
    };

    values: {
      attachment_name: string;
      body: string;
      created_time_server: string;
      highlight: { attachment_name: string | null; body: string };
      is_group: boolean;
      message_id: string;
      recipient_token: string;
      sender_token: string;
      sort_number: number;
      ttl: number;
    }[];
  };
  organization_id: string;
  type: string;
};

export type KeywordSearchResponse = {
  metadata: {
    continuation?: string | null;
    first_hit: number;
    total_hits: number;
  };
  results: KeywordSearchGeneralResult[] | KeywordSearchSecondLevelResult[];
};

const mapSearchParityToLegacyResponse = (
  results: SearchParityResponse['results']
): LegacyResponse['results'] => {
  return results.reduce((prev, v) => {
    return prev.concat(v.values);
  }, [] as Result[]);
};

export default class SearchAPI extends BaseAPI {
  async query(options: unknown, _version?: VersionNumber) {
    const { version = VERSION_TWO } = super.getVersion(SEARCH_QUERY, _version);

    if (version !== 'v8') {
      const res = await this.httpClient.post<LegacyResponse>('/:version/search', {
        data: options,
        urlParams: {
          version,
        },
      });
      return res.data;
    }

    const res = await this.httpClient.post<SearchParityResponse>('/:version/search', {
      data: options,
      urlParams: {
        version,
      },
    });

    const metadata = res.data.metadata;
    const entity_order = res.data.results.map((v) => v.entity_type);
    const results = mapSearchParityToLegacyResponse(res.data.results);
    return {
      metadata: { ...metadata, entity_order },
      results,
    };
  }

  async patientQuery(options: unknown, _version?: VersionNumber) {
    const { version } = super.getVersion(SEARCH_QUERY, _version);

    if (version !== 'v8') {
      const res = await this.httpClient.post<LegacyResponse>('/:version/patient/search', {
        data: options,
        urlParams: {
          version,
        },
      });
      return res.data;
    }

    const res = await this.httpClient.post<SearchParityResponse>('/:version/patient/search', {
      data: options,
      urlParams: {
        version,
      },
    });

    const metadata = res.data.metadata;
    const entity_order = res.data.results.map((v) => v.entity_type);
    const results = mapSearchParityToLegacyResponse(res.data.results);
    return {
      metadata: { ...metadata, entity_order },
      results,
    };
  }

  async keywordQuery(options: unknown) {
    const res = await this.httpClient.post<KeywordSearchResponse>('/v8/search', {
      data: options,
      urlParams: {
        version: 'v8',
      },
    });

    return res.data;
  }
}
