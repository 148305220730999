import React, { Component } from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as SideArrowSvg } from '../images/side-arrow.svg';
import { DndBanner } from '../../widgets/messenger/components/ConversationPane';
import { Switch } from './WebComponents';
import CallButton from './Call/CallButton';
import PfCallOrigins from './../../models/enums/PfCallOrigins';
import { MuteButton, Scrollbars, UserAvatar, UserName, UserTitleDepartment } from './';

const classes = BEM.with('UserConversationInfoPane');

const HANDLE_STYLE = {
  borderColor: '#d8d8d8',
  height: 21,
  marginLeft: -14,
  marginTop: -9,
  width: 21,
};
const MAX_MESSAGE_TIME_TO_LIVE_DAYS = 30;
const TRACK_AND_RAIL_STYLE = { backgroundColor: '#d8d8d8' };

class UserConversationInfoPane extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    counterPartyProfile: PropTypes.object.isRequired,
    currentOrganizationId: PropTypes.string.isRequired,
    findUser: PropTypes.func.isRequired,
    hasCurrentConversation: PropTypes.bool.isRequired,
    isAvailabilityFeatureFlagEnabled: PropTypes.bool.isRequired,
    isComposing: PropTypes.bool.isRequired,
    isPrintModeDisabled: PropTypes.bool.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    isRecipientPickerOpen: PropTypes.bool,
    isVideoCallEnabled: PropTypes.bool.isRequired,
    isVoiceCallEnabled: PropTypes.bool.isRequired,
    openPrintMode: PropTypes.func.isRequired,
    selectedRecipients: PropTypes.array,
    showDeleteConversationModal: PropTypes.func.isRequired,
    showLeaveGroupModal: PropTypes.func.isRequired,
    user: propTypes.user.isRequired,
  };

  componentDidMount() {
    const { conversation, findUser, user } = this.props;
    const { organizationId } = conversation;

    findUser(user.id, organizationId);
  }

  render() {
    const {
      conversation,
      counterPartyProfile,
      currentOrganizationId,
      hasCurrentConversation,
      isAvailabilityFeatureFlagEnabled,
      isComposing,
      isPrintModeDisabled,
      isProviderNetwork,
      isRecipientPickerOpen = false,
      isVideoCallEnabled,
      isVoiceCallEnabled,
      openPrintMode,
      selectedRecipients = [],
      user,
    } = this.props;
    const { counterPartyType, isDeletable, isMuted, organization } = conversation;
    const { deleteOnRead, messageTimeToLive } = organization;

    return (
      <div className={classes()}>
        <Scrollbars shouldScrollOverflow={true}>
          <div className={classes('name-and-avatar')}>
            <div className={classes('avatar-container')}>
              <UserAvatar
                ariaLabel="Info Pane Avatar Image"
                indicatorSize={'LARGE'}
                showPresenceIndicator={true}
                size={65}
                user={user}
                isMuted={isMuted}
              />
            </div>
            <UserName ariaLabel="Info Pane Name" className={classes('name')} user={user} />
            <UserTitleDepartment
              className={classes('title-department')}
              organizationId={organization.id}
              user={user}
            />
            {(isVideoCallEnabled || isVoiceCallEnabled) && (
              <div className={classes('call-container')}>
                <CallButton entity={user} origin={PfCallOrigins.CONVERSATION_DETAILS} />
              </div>
            )}
          </div>
          {isAvailabilityFeatureFlagEnabled && isProviderNetwork && (
            <DndBanner
              counterParty={conversation?.counterParty}
              counterPartyProfile={counterPartyProfile}
              currentOrganizationId={currentOrganizationId}
              hasCurrentConversation={hasCurrentConversation}
              isComposing={isComposing}
              isRecipientPickerOpen={isRecipientPickerOpen}
              selectedRecipients={selectedRecipients}
            />
          )}
          <div className={classes('options')}>
            <div className={classes('title')}>OPTIONS</div>
            <MuteButton conversation={conversation} />
            {!isPrintModeDisabled && (
              <div
                aria-label="Info Pane Print"
                className={classes('option-row')}
                id={'print-mode'}
                onClick={openPrintMode}
              >
                <div className={classes('option-header')}>PRINT MODE</div>
                <div>
                  <SideArrowSvg className={classes('side-arrow')} />
                </div>
              </div>
            )}
            <div className={classes('option-row-slider')}>
              <div className={classes('option-header-slider')}>
                Message Lifespan: {this._formatMessageTimeToLive(messageTimeToLive)}
              </div>
              <div className={classes('option-header-second-slider')}>Controlled by Admin</div>
              <div className={classes('option-slider')}>
                <Slider
                  max={30}
                  min={0}
                  value={30}
                  trackStyle={TRACK_AND_RAIL_STYLE}
                  railStyle={TRACK_AND_RAIL_STYLE}
                  handleStyle={HANDLE_STYLE}
                />
              </div>
            </div>
            <div className={classes('option-row', { disabled: true })}>
              <div className={classes('option-header', { disabled: true })}>DELETE ON READ</div>
              <div>
                <Switch disabled={true} checked={deleteOnRead} dataTestId="deleteOnRead" />
              </div>
            </div>
            <div
              aria-label="Info Pane Delete"
              className={classes('option-row', { disabled: !isDeletable })}
              onClick={
                counterPartyType === 'group'
                  ? this._showLeaveGroupModal
                  : this._showDeleteConversationModal
              }
            >
              <div className={classes('option-header', { disabled: !isDeletable })}>
                DELETE CONVERSATION
              </div>
              <div>
                <SideArrowSvg className={classes('side-arrow')} />
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }

  _showDeleteConversationModal = () => {
    const { conversation, showDeleteConversationModal } = this.props;
    const { isDeletable } = conversation;

    if (isDeletable) {
      showDeleteConversationModal(conversation);
    }
  };

  _showLeaveGroupModal = () => {
    const { conversation, showLeaveGroupModal } = this.props;
    const { counterParty, isDeletable } = conversation;

    if (isDeletable) {
      showLeaveGroupModal(counterParty);
    }
  };

  _formatMessageTimeToLive = (minutes) => {
    const hours = minutes / 60;
    let days = hours / 24;
    days = days > MAX_MESSAGE_TIME_TO_LIVE_DAYS ? MAX_MESSAGE_TIME_TO_LIVE_DAYS : days;

    if (days >= 1) {
      return pluralize('Day', Math.ceil(days), true);
    } else if (hours >= 1) {
      return pluralize('Hour', Math.ceil(hours), true);
    }

    return pluralize('Minute', Math.ceil(minutes), true);
  };
}

export default mobxInjectSelect({
  callStore: ['isVideoCallEnabled', 'isVoiceCallEnabled'],
  composeMessageStore: ['isComposing', 'isRecipientPickerOpen'],
  conversationStore: [
    'counterPartyProfile',
    'hasCurrentConversation',
    'showDeleteConversationModal',
    'selectedRecipients',
  ],
  groupEditorStore: ['showLeaveGroupModal'],
  messengerStore: [
    'currentOrganizationId',
    'isAvailabilityFeatureFlagEnabled',
    'isPrintModeDisabled',
    'openPrintMode',
  ],
  networkStore: ['isProviderNetwork'],
  userStore: ['findUser'],
})(UserConversationInfoPane);
